import React from 'react';

export const faqEntries = [
    {
        "summary" : "What is the process of buying and installing a standby generator?",
        "id" : "1",
        "detail" : <ol>
        <li>Go to <a href="https://backup.entergy.com/">https://backup.entergy.com/</a> to find and choose a standby generator.</li>
        <li>Submit your information to begin the purchase process.</li>
        <li>A verified third-party expert will contact you to schedule a home assessment. </li>
        <li>During the home assessment, the third-party expert will verify your home’s details to confirm your generator selection and installation costs. They’ll answer any questions you have about installation, payment, and financing.</li>
        <li>Installation will be scheduled based on product and installer availability. </li>
        <li>The installation team will install the generator and perform testing to ensure proper operation.</li>
        </ol>
    },
    {
        "summary" : "Is financing available?",
        "id" : "2",
        "detail" : <p>Yes, there are multiple financing options available to fit your budget needs. The third-party generator expert will work with you to secure financing through their financing partners.</p>
    },
    {
        "summary" : "Why should I consider installing a standby generator?",
        "id" : "3",
        "detail" : <p>A standby generator automatically turns on during an outage to power your home without having to deal with any extension cords or manual fueling. It can be sized to power your entire home or just essentials like refrigerators, internet, and medical devices, giving you peace of mind during an outage. </p>
    },
    {
        "summary" : "How long does a standby generator last?",
        "id" : "3",
        "detail" : <p>If it is properly maintained, a well-made backup generator should last about 3,000 hours. This means that if you run the generator for about 100 hours per year, your generator will last about 30 years.</p>
    },       
    {
        "summary" : "Am I eligible for a discount?",
        "id" : "4",
        "detail" : <p>All Entergy New Orleans customers who own their home are eligible to participate in this offering and receive discounted pricing from the third-party generator expert.</p>
    }, 
    {
        "summary" : "Does a standby generator require maintenance?",
        "id" : "5",
        "detail" : <p>While most standby generators automatically perform a “self-test” weekly and can be remotely monitored for issues, they do require regular maintenance to ensure they’ll be operational during an outage. If you choose to enter into a service agreement with a third-party generator expert, that expert can perform the necessary tests and maintenance at an additional cost in accordance with a service agreement.    </p>
    }, 
    {
        "summary" : "What is included in the estimated installation price?",
        "id" : "6",
        "detail" : <>
            <p>
            The estimated installation price includes labor and materials as well as: 
            </p>
            <ol>
                <li>Generac GenPad</li>
                <li>Up to 20' feet of electrical </li>
                <li>Up to 20' feet of gas</li>
                <li>Battery</li>
                <li>Startup</li>
                <li>Up to 1 delay module</li>
                <li>Gas meter upgrade</li>
                <li>5 year standard warranty</li>
                <li>General permitting (does not include unique permitting requirements)</li>
            </ol>
            <p>
                Some homes will have requirements that cause the installation price to increase.
            </p>
            </>
    }, 
    {
        "summary" : "How long can a generator run?",
        "id" : "7",
        "detail" : <><p>Natural gas generators are connected to a home’s natural gas lines, so in theory, they can run indefinitely. Most generator manufacturers, however, recommend that you run a generator for a maximum of 500 hours at a time, or just shy of 21 days. This will give you a chance to check oil and coolant levels and let the generator cool down. While running a generator for extended periods of time, you should check on it every so often to make sure it’s not overheating or malfunctioning.
        </p><p>Generators that use other fuel types will run until the fuel is depleted, but use of those generators should not exceed the run-time recommendations for natural gas generators.
        </p></>
    }, 
    {
        "summary" : "Are standby generators safe?",
        "id" : "8",
        "detail" : <p>Generators are generally safe when operated and maintained correctly. For more information, go to <a href="https://www.entergynewsroom.com/storm-center/generators/">https://www.entergynewsroom.com/storm-center/generators/</a></p>
    }, 
    {
        "summary" : "How long will it take to install a generator?",
        "id" : "9",
        "detail" : <>
            <p>When viewing products on the website, you'll be able to view an estimated timeline for installation.</p>
            <p>Due to high demand and supply chain issues, it can take longer than normal get a standby generator installed.</p>
            <p>Currently, product availability is the biggest contributor to increased wait times but permitting and inspections can also cause delays in the installation process.</p>
        </>
    },
    {
        "summary" : "What is your contact information?",
        "id" : "contact",
        "detail" : <p>If you have any questions that aren’t answered here, please email us at <a href="mailto:communitybackup@entergy.com">communitybackup@entergy.com</a> and we’ll respond in 24 - 48 hours.</p>
    },
    {
        "summary" : "Are businesses eligible for this program?",
        "id" : "business",
        "detail" : <p>
            Certain businesses are eligible for a discount through this program. Please email us at <a href="mailto:communitybackup@entergy.com">communitybackup@entergy.com</a> to learn more and see if your business qualifies.
            </p>
    }     
]
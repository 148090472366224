import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './FAQ.scss';

export default function FAQ(props) {
    const { data } = props;

    return (
        <faq-container id="faq">
            <grid-container>
                <grid-row>
                    <grid-item-full class="header">
                        <center><h2>Frequently Asked Questions</h2></center>
                    </grid-item-full>
                </grid-row>
                    <grid-item-full>
                        { data.map((item, index) => (
                            <Accordion key={index} id={item.id} expanded={item.expanded}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                    {item.summary}
                                </AccordionSummary>
                                <AccordionDetails>
                                        {item.detail}
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </grid-item-full>
            </grid-container>
        </faq-container>
    );
}
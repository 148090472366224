import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import './Carousel.scss'

export function Carousel(props) {
    
    const settings = {
		autoplay: true,
		autoplaySpeed: 10000,
		arrows: false,
        dotsClass: 'carousel-dots',
        dots: true,
        infinite: true
    };

    return (
        <Slider {...settings}>
            {props.children}
        </Slider>
    );
}


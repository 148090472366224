import { CircularProgress, Grid, MenuItem, Modal, Select, Typography } from "@mui/material"
import { StyledButtonOutlined, StyledButtonSentenceCase } from "../../StyledButton"
import { useEffect, useState } from "react";
import './StatusChange.scss'
import { signupStatuses } from "../../../constants/transferSwitch/signupStatuses";

function StatusChange({open, onClose, currentStatus, onSave, loading}) {
    const [newStatus, setNewStatus] = useState('')

    useEffect(()=>{
      setNewStatus(currentStatus)
    },[currentStatus])

    return (
    <Modal open={open} onClose={onClose}>
        <Grid container className="spinner update-status-modal" spacing={2}>
        <Grid item container xs={12} justifyContent="flex-start">
          <Typography variant="h5" >
            Update status
          </Typography>
        </Grid>
        <Grid item container xs={12} justifyContent="flex-start" className="status-change-select">
          <Select
            className="update-status-select"
            id="update-status-select"
            value={newStatus}
            onChange={(e)=>setNewStatus(e.target.value)}>
                <MenuItem value={signupStatuses.PENDING}>{signupStatuses.PENDING}</MenuItem>
                <MenuItem value={signupStatuses.APPROVED}>{signupStatuses.APPROVED}</MenuItem>
                <MenuItem value={signupStatuses.DENIED}>{signupStatuses.DENIED}</MenuItem>
                <MenuItem value={signupStatuses.CANCELED}>{signupStatuses.CANCELED}</MenuItem>
                <MenuItem value={signupStatuses.NEEDS_REVIEW}>{signupStatuses.NEEDS_REVIEW}</MenuItem>
          </Select>
        </Grid>
        <Grid item container justifyContent="flex-end" gap={1}>
          {loading ? <CircularProgress/> : 
          <>
          <StyledButtonOutlined onClick={onClose} variant="text" color="primary">
            Cancel
          </StyledButtonOutlined>
          <StyledButtonSentenceCase onClick={()=>onSave(newStatus)} variant="text" color="primary">
            Save
          </StyledButtonSentenceCase>
          </>
          }
        </Grid>
        </Grid>
      </Modal>
    )
}

export default StatusChange

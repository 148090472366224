import { StyledButton } from '../../StyledButton';
import ribbonImg from '../../../assets/icon-ribbon.svg';
import pinkRibbonImg from '../../../assets/icon-ribbon-pink.svg';

import './GenCard.scss';

export default function GenCard(props) {
    const { item, selected, onShowDetail } = props;

    return (
        <>
            {item ? 
                <card-container>
                    <card-row>
                    <card-badge>
                        <img src={ (item.selectionType === "bestFit" ? pinkRibbonImg : ribbonImg )} width="30" height="40" alt="Ribbon" />
                    </card-badge>
                    <card-header class={ (item.selectionType === "bestFit" ? "pink-highlight" : "")}>
                    
                        { item.selectionType === "bestFit" ? "Best Fit" : ""}
                        { item.selectionType === "budget" ? "Budget" : ""}
                        { item.selectionType === "upgrade" ? "Upgrade" : ""}
                    </card-header>
                    </card-row>
                    <card-row>
                        <card-item-center>
                            <img src={item.image} alt="Generator" width="175px" />
                        </card-item-center>
                    </card-row>
                    <card-heading>
                        {item.name}
                    </card-heading>
                    <card-content>
                        <card-row class="subheading">Est. Installation in {item.leadTime}</card-row>
                        <card-row>
                        <card-item-left>Cost + Installation</card-item-left> 
                        <card-item-right>${item.fullCost} - ${item.fullCostMax}</card-item-right>
                        </card-row>
                        <card-row class="pink-highlight">
                        <card-item-left>Entergy Discount</card-item-left>  
                        <card-item-right >${item.discount}</card-item-right>
                        </card-row>
                        <card-row>
                        <card-item-left>Total Estimate</card-item-left> 
                        <card-item-right>${item.total} - ${item.totalMax}</card-item-right>
                        </card-row>
                    </card-content>
                    <card-action>
                        <center>
                            <StyledButton 
                                disabled={selected} 
                                onClick={() => { onShowDetail(item); }}>
                                {selected? "SELECTED GENERATOR" : "SELECT GENERATOR" }
                            </StyledButton>
                        </center>
                    </card-action>
                </card-container>
            : <></> }
        </>
    ) 
}
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { StyledButtonOutlined } from '../../StyledButton';

import './FAQ.scss';
import { useState } from "react";

export default function FAQ(props) {
    const { data } = props;

    const [faqGroups, setFaqGroups] = useState(data.slice(0,1))

    return (
        <grid-container id="faq" class="faq-container">
            <grid-row>
                <grid-item-full >
                    <center><h2>Frequently Asked Questions</h2></center>
                </grid-item-full>
            </grid-row>
            <grid-item-full>
                { faqGroups.map(group => (
                    <grid-item-full key={group.id}>
                    <h3>{group?.groupTitle}</h3>
                    { group?.questions?.map(item => (
                        <Accordion key={item.id} id={item.id} expanded={item.expanded}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                {item.summary}
                            </AccordionSummary>
                            <AccordionDetails>
                                {item.detail}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                    </grid-item-full>
                ))}
            </grid-item-full>
            {faqGroups?.length === 1 ? 
            <grid-row>
                <grid-item-full >
                    <center><StyledButtonOutlined onClick={() => setFaqGroups(data)}>More FAQs</StyledButtonOutlined></center>
                </grid-item-full>
            </grid-row>
            :
            <grid-row>
                <grid-item-full >
                    <center><StyledButtonOutlined onClick={() => setFaqGroups(data.slice(0,1))}>Less FAQs</StyledButtonOutlined></center>
                </grid-item-full>
            </grid-row>
            }
        </grid-container>
    );
}
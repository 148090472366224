import axios from 'axios';

export const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

export function setRequestHeader(token) {
  API.interceptors.request.use(req => {
    
    if(token)
      req.headers.Authorization = "Bearer {}".replace("{}", token);
    else
      req.headers.Authorization = "";

    return req;
  });
}

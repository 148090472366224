import { Avatar, Checkbox, CircularProgress, FormControl, FormHelperText, InputAdornment, RadioGroup, TextField, useMediaQuery } from '@mui/material';
import InputMask from "react-input-mask";
import { generatorCalc, suggestGenerators } from '../../../calc/generatorCalc';
import { appliances } from '../../../constants/resiliency/appliances';
import { ClickChip } from '../ClickChip/ClickChip';
import { StyledButton } from '../../StyledButton';
import { StyledRadio } from '../../StyledRadio';
import { allowedZipCodes } from '../../../constants/resiliency/allowedZipCodes';
import './GenQuestions.scss';
import { useRef, useState } from 'react';

export default function GenQuestions(props) {
    const { data, onDataChange, loading } = props;
    const [errors, setErrors] = useState({});
    const ref = useRef(null);
    const desktopScreenSize = useMediaQuery("(min-width:1024px)");
    const requiredValues = ["zipCode", "rentOrOwn", "fuelSource", "sqft", "monthlyBill"];

    const [essentialsCheck, setEssentialsCheck] = useState(data.essentials ?? false)
    const [appliancesCheck, setAppliancesCheck] = useState(data.appliances ?? false)
    const [otherCheck, setOtherCheck] = useState(data.other ?? false)

    const onSubmit = (e) => {
        e.preventDefault();

        if(formValidate(data, requiredValues)) {
            const kw = generatorCalc(data.sqft, data.monthlyBill, data.selectedAppliances);
            props.setShowGenerators(suggestGenerators(kw));
        }
        else {
            ref.current.scrollIntoView();
        }
    }
    
    const handleSelect = (name, value) => {
        onDataChange({
            ...data,
            [name] : value
        }); 
    } 

    const handleGroupCheck = (name, checked) => {
        switch(name){
            case 'essentials':
                setEssentialsCheck(checked)
                break
            case 'appliances':
                setAppliancesCheck(checked)
                break
            case 'other':
                setOtherCheck(checked)
                break
            default:
                break
        }
        const appList = checkGroup(name, checked);
        onDataChange({
            ...data,
            [name] : checked,
            "selectedAppliances" : appList
        });
    }

    const checkGroup = (groupName, checked) => {
        const groupList = appliances.filter((item) => (item.heading === groupName));
        let appList = [...data.selectedAppliances];
        groupList.forEach((item) => {
            const isChecked = appList.find((selItem) => (selItem === item.name));
            if(!isChecked && checked)
                appList.push(item.name);
            else if(isChecked && !checked)
                appList.splice(appList.indexOf(item.name), 1);
        });

        return appList;
    }

    const handleChipClick = (label, heading) => {
        let appList = [...data.selectedAppliances];
        let groupList = appliances.filter((item) => (item.heading === heading))
        let allGroupChecked = true;
        const isChecked = appList.find((item) => (item === label));

        if(isChecked) {
            handleGroupCheck(heading, false);
            appList.splice(appList.indexOf(label), 1);
            onDataChange({
                ...data,
                [heading] : false,
                'selectedAppliances': appList
            });
        }
        else {
            appList.push(label)

            groupList.forEach(groupItem => {
                if(!appList.find((item) => (item === groupItem.name))){
                    allGroupChecked = false
                }
            })

            if( allGroupChecked ){
                handleGroupCheck(heading, true);
                onDataChange({
                    ...data,
                    [heading] : true,
                    'selectedAppliances': appList
                });
            }
            else{
                onDataChange({
                    ...data,
                    'selectedAppliances': appList
                });
        }
        }        
        
    }

    const handleChange = (e) => {
        handleSelect(e.target.name, e.target.value);
    }

    const formValidate = (inData, reqValues) => {
        
        let errs = {};

        reqValues.forEach((item) => {
            errs[item] = (!inData[item]) ? true : false;
        });

        errs['zipError'] = allowedZipCodes.find((item) => (item === inData.zipCode)) ? false : true;
        errs['rentOrOwnError'] = inData.rentOrOwn === "rent";

        setErrors({ ...errs });

        return Object.values(errs).every((item) => (item === false));
    }

    const renderChipList = (heading) => {
        return (                        
            <chip-column>
                { appliances.filter((item) => (item.heading === heading)).map((item, index) => (
                    <ClickChip 
                        key={index} 
                        avatar={<Avatar alt={item.name} src={item.icon} variant="square" />}
                        checked={data.selectedAppliances.find((i) => (i === item.name))} 
                        label={item.name} 
                        onChipClick={() => handleChipClick(item.name, heading)} 
                    />
                ))}
            </chip-column>
        );
    }
    
    return (
        <form id="frmGenQuestions" onSubmit={onSubmit} ref={ref}>
            <grid-container>
                <grid-row>
                    <grid-item-full>
                        <center><h2>Customize Your Generator Estimate</h2></center>
                    </grid-item-full>
                </grid-row>
                <grid-row>
                    <grid-item-third>
                        <p>What is your zip code?</p>
                        <InputMask mask="99999" value={data.zipCode ?? ""} onChange={handleChange} maskPlaceholder="">
                        <TextField 
                            
                            id="zip-code" 
                            name="zipCode" 
                            fullWidth
                            error={errors["zipError"] || errors["zipCode"]}
                            helperText={ 
                                errors["zipCode"] ? "This field is required." : 
                                (errors["zipError"] ? "This program is currently only available to Entergy New Orleans homeowners" : " ")
                                } />
                        </InputMask>
                    </grid-item-third>
                    <grid-item-third>
                        <div>Do you rent or own?</div>
                        <FormControl error={errors["rentOrOwnError"] || errors["rentOrOwn"]} >
                            <RadioGroup name="rentOrOwn" aria-labelledby="lblRentOrOwn" value={data.rentOrOwn ?? ""} onChange={handleChange}>
                                <div><StyledRadio value="own" />Own</div>
                                <div><StyledRadio value="rent"/>Rent</div>
                            </RadioGroup>
                            <FormHelperText>
                                {errors["rentOrOwn"] ? "This field is required." : 
                                (errors["rentOrOwnError"] ? "This program is currently only available to Entergy New Orleans homeowners" : " ") }
                            </FormHelperText>
                            
                        </FormControl>
                    </grid-item-third>
                    <grid-item-third>
                        <div>What fuel source do you want to use?</div>
                        <FormControl required error={errors["fuelSource"]}>
                            <RadioGroup name="fuelSource" value={data.fuelSource ?? ""} onChange={handleChange}>
                                <div><StyledRadio value="gas" />Natural Gas</div>
                                <div><StyledRadio value="propane" />Propane</div>
                                <div><StyledRadio value="notSure" />Not Sure</div>
                            </RadioGroup>
                            <FormHelperText>{errors["fuelSource"] ? "This field is required." : " "} </FormHelperText>
                        </FormControl>
                    </grid-item-third>
                </grid-row>
                <grid-row-bottom-align>
                    <grid-item-third>
                        <p>What is the square footage of your home?</p>
                        <InputMask mask="99999" value={data.sqft ?? ""} onChange={handleChange} maskPlaceholder="">
                            <TextField 
                                id="sqft" 
                                name="sqft"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">sqft</InputAdornment>}}
                                error={errors["sqft"]}
                                fullWidth>    
                            </TextField>
                        </InputMask>
                    </grid-item-third>
                    <grid-item-two-third>
                        <p>How much do you pay per month for electricity on average?</p>
                        <InputMask mask="99999.99" value={data.monthlyBill ?? ""} onChange={handleChange} maskPlaceholder="">
                            <TextField 
                                name="monthlyBill"
                                id="monthly-bill" 
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>}}
                                error={errors["monthlyBill"]}
                                sx={desktopScreenSize ? { width: "50%"  } : { width: "100%" }}
                                >   
                            </TextField>
                        </InputMask>
                    </grid-item-two-third>
                </grid-row-bottom-align>
                <grid-row>
                    <grid-item-full>
                        <h3>What do you want to power?</h3>
                    </grid-item-full>
                </grid-row>
                <grid-row>
                    <grid-item-third>
                        <span>
                            <Checkbox 
                                id="essentials"
                                name="essentials"
                                checked={essentialsCheck}
                                onChange={(e) => handleGroupCheck(e.target.name, e.target.checked)}/>
                                All Home Essentials
                        </span>
                        {renderChipList("essentials")}
                    </grid-item-third>
                    <grid-item-third>
                        <span>
                            <Checkbox 
                                id="appliances"
                                name="appliances"
                                checked={appliancesCheck}
                                onChange={(e) => handleGroupCheck(e.target.name, e.target.checked)}/>
                            All Large Appliances
                        </span>
                        {renderChipList("appliances")}
                    </grid-item-third>
                    <grid-item-third>
                        <span>                        
                            <Checkbox 
                                id="other"
                                name="other"
                                checked={otherCheck}
                                onChange={(e) => handleGroupCheck(e.target.name, e.target.checked)}/>
                            Other
                        </span>
                        {renderChipList("other")}
                    </grid-item-third>
                </grid-row>
                <grid-row-spacer></grid-row-spacer>
                <grid-row>
                    <grid-item-full>
                        <center>
                        { loading ? 
                            <CircularProgress size={'2.6rem'} /> 
                            :
                            <StyledButton variant="contained" type="submit" disabled>Recommend Generator</StyledButton>
                        }
                        </center>
                    </grid-item-full>
                </grid-row>
            </grid-container>
        </form>
    )
    
  }
import "./App.scss";
import Home from "./pages/resiliency/Home";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import TagManager from "react-gtm-module";
import { AuthContext } from "./context/authContext";
import OauthSend from "./components/Oauth/OauthSend";
import OauthReceive from "./components/Oauth/OauthReceive";
import { AuthService } from "./components/Oauth/AuthService";
import { Routes, Route } from "react-router-dom";
import TransferSwitchHome from "./pages/transferSwitch/Home";
import { OpcoProvider } from "./context/opcoContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { Toaster } from "react-hot-toast";
import Unauthorized from "./pages/Unauthorized";

if (process.env.REACT_APP_GOOGLE_GTAG_KEY !== "")
  TagManager.initialize({ gtmId: process.env.REACT_APP_GOOGLE_GTAG_KEY });

function ProvideAuth({ children }) {
  const auth = AuthService();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // globally default to 60 seconds
      staleTime: 1000 * 60,
      refetchOnWindowFocus: false
    },
  },
})

function App() {
  return (
    <OpcoProvider>
      <BrowserRouter>
      <Toaster
        position='top-center' 
        gutter={12} 
        containerStyle={{margin: '8px'}} 
        toastOptions={{success: { duration: 3000 }, error: {duration: 5000}, style: {fontSize: '16px', maxWidth: '500px', padding: '16px 24px'}}}/>
      <QueryClientProvider client={queryClient}>
        <Home />

        <ProvideAuth>
          <TransferSwitchHome/>

          <Routes>
            <Route path="/authcallback" element={<OauthReceive />} />
            <Route path="/login" element={<OauthSend />} />
            <Route path="/unauthorized" element={<Unauthorized/>}/>
          </Routes>
        </ProvideAuth>

        <ScrollToTop />
        </QueryClientProvider>
      </BrowserRouter>
    </OpcoProvider>
  );
}

export default App;

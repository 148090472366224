import './PageNotFound.scss';
import { useNavigate } from "react-router";
import { StyledButtonSentenceCase } from "../StyledButton";

export default function PageNotFound({ url, children }) {
  const navigate = useNavigate();

    return (
        <page-not-found>
            <page-not-found-text>
                The page you are looking for could not be found
            </page-not-found-text>
            <StyledButtonSentenceCase onClick={()=>navigate(url)} size="large">
                {children}
            </StyledButtonSentenceCase>
        </page-not-found>
    )
}

import generatorImg from '../../assets/generac-img.png';

export const generators = [
    {
        "name" : "Generac Guardian 18kW",
        "image" : generatorImg,
        "leadTime" : "12-14 Weeks",
        "cost" : "5,769.00",
        "installation" : "7,789.00",
        "installationMax" : "12,789.00",
        "discount" : "983.04",
        "total" : "12,574.96",
        "fullCost" : "13,558.00",
        "fullCostMax" : "18,558.00",
        "totalMax" : "17,574.96",
        "watts": "18kW",
        "wattage": 18000,
        "financing18": "698.61",
        "financing37": "373.06",
        "financing61": "251.51",
        "financing132": "157.37",
        "model": "7228",
        "engineType" : "Generac G-Force 800 Series",
        "fuelType" : "Natural Gas or LP Gas Operation",
        "enclosure" : "Tough, Durable, All-Aluminum",
        "spec" : "/spec/generac-spec-sheet-10kw-14kw-18kw-en_1.pdf",
        "controllerType" : "Evolution",
        "modelFeatures" : [
            "Installation as close as 18in to a structure"
        ]
    },
    {
        "name" : "Generac Guardian 22kW",
        "image" : generatorImg,
        "leadTime" : "12-14 Weeks",
        "cost" : "6,279.00",
        "installation" : "7,789.00",
        "installationMax" : "12,789.00",
        "discount" : "1,069.94",
        "total" : "12,998.06",
        "fullCost" : "14,068.00",
        "fullCostMax" : "19,068.00",
        "totalMax" : "17,998.06",
        "watts": "22kW",
        "wattage": 22000,
        "financing18": "722.11",
        "financing37": "385.61",
        "financing61": "259.98",
        "financing132": "162.66",
        "model": "7043",
        "engineType" : "Generac G-Force 1000 Series",
        "fuelType" : "Natural Gas or LP Gas Operation",
        "enclosure" : "Tough, Durable, All-Aluminum",
        "controllerType" : "Evolution",
        "spec" : "/spec/generac-20-24kw-spec-sheet-without-pwrview-a0000937814_2.pdf",
        "modelFeatures" : [
            "U.S. EPA Certification for Non-Emergency Applications",
            "Installation as close as 18in to a structure"
        ]
    },
    {
        "name" : "Generac Guardian 24kW",
        "image" : generatorImg,
        "leadTime" : "12-14 Weeks",
        "cost" : "6,529.00",
        "installation" : "7,789.00",
        "installationMax" : "12,789.00",
        "discount" : "1,112.54",
        "total" : "13,205.46",
        "fullCost" : "14,318.00",
        "fullCostMax" : "19,318.00",
        "totalMax" : "18,205.46",
        "watts": "24kW",
        "wattage": 24000,
        "financing18": "733.64",
        "financing37": "391.76",
        "financing61": "264.12",
        "financing132": "165.26",
        "model": "7210",
        "engineType" : "Generac G-Force 1000 Series",
        "fuelType" : "Natural Gas or LP Gas Operation",
        "enclosure" : "Tough, Durable, All-Aluminum",
        "controllerType" : "Evolution",
        "spec" : "/spec/generac-20-24kw-spec-sheet-without-pwrview-a0000937814_2.pdf",        
        "modelFeatures" : [
            "U.S. EPA Certification for Non-Emergency Applications",
            "Takes up to 70% less space**",
            "Installation as close as 18in to a structure"
        ]
    },     
    {
        "name" : "Generac Guardian 26kW",
        "image" : generatorImg,
        "leadTime" : "12-14 Weeks",
        "cost" : "7,139.00",
        "installation" : "7,789.00",
        "installationMax" : "12,789.00",
        "discount" : "1,216.49",
        "total" : "13,711.51",
        "fullCost": "14,928.00",
        "fullCostMax" : "19,928.00",
        "totalMax" : "18,711.51",
        "watts": "26kW",
        "wattage": 26000,
        "financing18": "706.20",
        "financing37": "377.11",
        "financing61": "254.24",
        "financing132": "159.07",
        "model": "7291",
        "engineType" : "Generac G-Force 1000 Series",
        "fuelType" : "Natural Gas or LP Gas Operation",
        "enclosure" : "Tough, Durable, All-Aluminum",
        "controllerType" : "Evolution",
        "spec" : "/spec/final-26kw-spec-sheet-5-25.pdf",
        "modelFeatures" : [
            "U.S. EPA Certification for Non-Emergency Applications",
            "28% more motor starting power*compared to competitive output air-cooled products",
            "Takes up to 70% less space**"
        ]
    },    
]
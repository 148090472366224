import './Footer.scss';
import logo from '../../assets/EntergyLogo_MAIN_WhiteType.png';
import fbLogo from '../../assets/f_logo_RGB-White_58 1.png';
import twitterLogo from '../../assets/Twitter social icons - circle - white 1.png';
import inLogo from '../../assets/In-White-34 1.png';

export default function Footer() {
    return (
      <>
      <footer>
        <footer-content>
          <img src={logo} alt="Entergy Logo" className="footerLogo" />
        </footer-content>
        <footer-link-row>
          <footer-links>
            <ul>
              <li><a href="https://www.entergy.com/contact-us/" target="__blank">Contact Us</a></li> |
              <li><a href="https://www.entergy.com/terms-of-use/"target="__blank">Terms of Use</a></li> |
              <li><a href="https://www.entergy.com/privacy-policy/" target="__blank">Privacy Policy</a></li> 
            </ul>
          </footer-links>
          <social-links>
            <ul>
              <li><a href="https://www.facebook.com/Entergy" target="__blank"><img src={fbLogo} alt="facebook" /></a></li>
              <li><a href="https://twitter.com/Entergy" target="__blank"><img src={twitterLogo} alt="twitter" /></a></li>
              <li><a href="https://www.linkedin.com/company/entergy" target="__blank"><img src={inLogo} alt="linkedin" /></a></li>
            </ul>
          </social-links>
        </footer-link-row>
        <footer-content>
          <footer-text>&copy; {new Date().getFullYear()} Entergy Corporation, All Rights Reserved. The Entergy name and logo are registered service marks of Entergy Corporation and may not be used without the express, written consent of Entergy Corporation</footer-text>
        </footer-content>
      </footer>
      </>
    )
  }
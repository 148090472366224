import React, { useState } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './HomeBenefits.scss';
import benefitsImg from '../../../assets/ts-image1-crop.jpeg';
import { useNavigate } from 'react-router';

import { homeBenefits } from '../../../constants/transferSwitch/homeBenefits';
import { StyledButtonOutlined } from '../../StyledButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useOpco } from '../../../context/opcoContext';


export function HomeBenefits() {
    const [selectedCard, setSelectedCard] = useState(1);
    const navigate = useNavigate();

    const {opco, opcoData: {displayPrice}} = useOpco();

    return (
        <>
        <benefits-title>
            Benefits of the Entergy Transfer Switch with Surge Protection Pilot Program
        </benefits-title>
        <benefits-wrapper>
            <benefits-data>
                { homeBenefits.map((item, i) => <Item key={i} item={item} selected={selectedCard} onSelect={setSelectedCard}/>)}
            </benefits-data>
            <benefits-media>
                <img src={benefitsImg} alt={"Generator"}  />
                <StyledButtonOutlined disabled onClick={() => navigate(`/transferSwitch/${opco}/signUp`)}>Sign up today for as low as ${displayPrice} per month</StyledButtonOutlined>
            </benefits-media>
        </benefits-wrapper>
        </>
    )
}

function Item(props) {
    const { item, selected, onSelect} = props;

    return (
        <>
            {selected === item.cardID ?
                <card-data-selected>
                    <card-title>
                        {item.name}
                        <ExpandLessIcon/>
                    </card-title>

                    <card-description>
                        {item.description}
                    </card-description>  
                </card-data-selected>
            :
                <card-data onClick={() => onSelect(item.cardID)}>
                    <card-title>
                        {item.name}
                        <ExpandMoreIcon/>
                    </card-title>
                </card-data>
            }
        </>
    )
} 


import { createContext, useContext, useEffect, useState } from "react";

const opcoSpecficData = {
    louisiana : {
        opcoDisplayOfficial : "Entergy Louisiana, LLC",
        opcoLink: 'https://www.entergy-louisiana.com/',
        opcoDisplayLong : "Louisiana",
        opcoDisplayShort : "ELL",
        ssoRequired: true,
        liveOpco: true,
        openProgram: true,
        price7year: "17.42",
        price4year: "24.43",
        price1year: "75.04",
        displayPrice: "17",
        monthlyPrice: "2.86"
    },
    texas : {
        opcoDisplayOfficial : "Entergy Texas, Inc.",
        opcoLink: 'https://www.entergy-texas.com/',
        opcoDisplayLong : "Texas",
        opcoDisplayShort : "ETI",
        ssoRequired: true,
        liveOpco: false,
        openProgram: false,
        price7year: "22",
        price4year: "31",
        price1year: "101",
        displayPrice: "22",
        monthlyPrice: "2.97"
    },
};

const OpcoContext = createContext();

function OpcoProvider({ children }) {

    const [opco, setOpco] = useState('')
    const [opcoData, setOpcoData] = useState({})

    useEffect(() => {
        let data = {}
        if(opco)
          data = opcoSpecficData[opco] ? opcoSpecficData[opco] : { liveOpco: false, showPageNotFound: true }
        setOpcoData(data)
      },[opco])

  
    return (
      <OpcoContext.Provider value={{ opcoData, setOpco, opco }}>
        {children}
      </OpcoContext.Provider>
    );
  }

function useOpco() {
    const context = useContext(OpcoContext);
    if (context === undefined)
      throw new Error("OpcoContext was used outside OpcoProvider");
    return context;
  }

export { OpcoProvider, useOpco }
import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";
import TopNav from "../../TopNav/TopNav";
import './Layout.scss';
import PageNotFound from "../../PageNotFound/PageNotFound";

export default function Layout({children, liveOpco=false, isSharedPage=false, showPageNotFound=false }) {

    return (
        <>
        <header-area>
          <TopNav/>
          <Navbar program='transferSwitch'/>
        </header-area>
        <div className={`main-area ${isSharedPage ? 'landing-page' : ''}`}>
          <content-area>
            {isSharedPage || liveOpco ? children : showPageNotFound ? 
            <PageNotFound url='/transferSwitch'>
              Return to home
            </PageNotFound> 
            : 
            null}
          </content-area>
        </div>
        <footer-color>
          <footer-area>
            <Footer/>
          </footer-area>
        </footer-color>
        </>
    )
}

export const allowedZipCodes = [
    "70122", 
    "70119",
    "70118", 
    "70115", 
    "70131", 
    "70127", 
    "70117", 
    "70126", 
    "70114", 
    "70124", 
    "70125", 
    "70128", 
    "70130", 
    "70129", 
    "70116", 
    "70113", 
    "70112", 
    "70146", 
    "70148", 
    "70140", 
    "70139", 
    "70145", 
    "70149", 
    "70151", 
    "70150", 
    "70153", 
    "70152", 
    "70156", 
    "70154", 
    "70158", 
    "70157", 
    "70160", 
    "70159", 
    "70162", 
    "70161", 
    "70164", 
    "70166", 
    "70165", 
    "70170", 
    "70167", 
    "70174", 
    "70172", 
    "70176", 
    "70175", 
    "70178", 
    "70177", 
    "70179", 
    "70182", 
    "70185", 
    "70184", 
    "70187", 
    "70186", 
    "70190", 
    "70189", 
    "70195", 
    "70163"
];
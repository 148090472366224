import { TextField, Grid, Autocomplete } from '@mui/material';
import InputMask from "react-input-mask";
import './PersonalInfo.scss';

export default function PersonalInfo(props) {
    const { data, onDataChange, errors } = props;
    const states = ["AR", "LA", "MS", "TX"];
    
    const handleSelect = (name, value) => {
        onDataChange({
            ...data,
            [name]: value
        });
    };

    const handleChange = (e) => {
        handleSelect(e.target.name, e.target.value);
    };

    const getErrorMessage = (fieldName) => {
        if(fieldName === "emailInvalid")
            return errors[fieldName] ? "Please enter a valid e-mail address." : " ";
        else if(fieldName === "phoneInvalid")
            return errors[fieldName] ? "Please enter a valid phone number." : " ";
        else if(fieldName === "accountNumber")
            return errors[fieldName] ? "This field is required. " : errors["accountInvalid"] ? "This program is not currently available in your area." : " ";
        else
            return errors[fieldName] ? "This field is required. " : " ";
    }

    return (
        <main-container>
            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <h3>Contact Details</h3>
                </Grid>
                <contact-details>
                <Grid item container xs={12} spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            id="firstName" 
                            name="firstName"
                            value={data.firstName ?? ""}
                            onChange={handleChange}
                            label="First Name" 
                            error={errors["firstName"]}
                            fullWidth 
                            helperText={getErrorMessage("firstName")}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            id="lastName" 
                            name="lastName"
                            value={data.lastName ?? ""}
                            onChange={handleChange}
                            error={errors["lastName"]}
                            helperText={getErrorMessage("lastName")}
                            label="Last Name" fullWidth />
                    </Grid>
                </Grid>
                <Grid item container xs={12} spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            id="address" 
                            name="address"
                            value={data.address ?? ""}
                            onChange={handleChange}
                            error={errors["address"]}
                            helperText={getErrorMessage("address")}
                            label="Installation Address" fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            id="city" 
                            name="city"
                            value={data.city ?? ""}
                            onChange={handleChange}
                            error={errors["city"]}
                            helperText={getErrorMessage("city")}
                            label="City" fullWidth />
                    </Grid>
                </Grid>
                <Grid item container xs={12} spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Autocomplete 
                            options={states}
                            onChange={(e, value)=>{handleSelect('state',value)}}
                            renderInput={(params) => 
                                <TextField 
                                    {...params} 
                                    value={data.state ?? ""} 
                                    helperText={getErrorMessage("state")} 
                                    error={errors["state"]} 
                                    label="State"
                                    id="state"
                                    name="state"
                                    />
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputMask mask="99999" value={data.zipCode ?? ""} onChange={handleChange} maskPlaceholder="">
                            <TextField 
                                id="zipCode"
                                name="zipCode"
                                error={errors["zipCode"]}
                                helperText={getErrorMessage("zipCode")}
                                label="Zip Code" fullWidth />
                        </InputMask>
                    </Grid>
                </Grid>
                <Grid item container xs={12} spacing={1}>
                    <Grid item xs={12} sm={6} style={(errors["phoneNumber"] || errors["phoneInvalid"]) ? {} : {marginBottom: '1rem'}}>
                        <InputMask 
                            mask="(999) 999-9999" 
                            maskPlaceholder='(XXX) XXX-XXXX'
                            value={data.phoneNumber ?? ""} 
                            onChange={handleChange}>
                            <TextField 
                                id="phoneNumber" 
                                name="phoneNumber"
                                inputMode="tel"
                                error={errors["phoneNumber"] || errors["phoneInvalid"]}
                                helperText={getErrorMessage("phoneNumber") + getErrorMessage("phoneInvalid")}
                                label="Phone Number" fullWidth />
                        </InputMask>
                    </Grid>
                    <Grid item xs={12} sm={6} style={(errors["email"] || errors["emailInvalid"]) ? {} : {marginBottom: '1rem'}}>
                        <TextField 
                            id="email"
                            name="email"
                            inputMode="email"
                            value={data.email ?? ""}
                            error={errors["email"] || errors["emailInvalid"]}
                            onChange={handleChange}
                            helperText={getErrorMessage("email") + getErrorMessage("emailInvalid")}
                            label="E-mail Address" fullWidth />
                    </Grid>
                </Grid>
                <Grid item container xs={12}>
                    <TextField 
                        id="accountNumber" 
                        name="accountNumber"
                        value={data.accountNumber ?? ""}
                        onChange={handleChange}
                        error={errors["accountNumber"] || errors["accountInvalid"]}
                        helperText={getErrorMessage("accountNumber")}
                        label="Entergy Service Account Number" fullWidth />
                </Grid>   
                </contact-details>             

            </Grid>
        </main-container>
    )
    
  }
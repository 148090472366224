import homeImg from '../../assets/icon-home.svg';
import hardhatImg from '../../assets/icon-hardhat.svg';
import phoneImg from '../../assets/icon-phone-pink.svg';
import handImg from '../../assets/icon-hand-money.png';

export const nextSteps = [
    {
        "title" : "Initial Phone Call",
        "subHeading" : "Estimated 1-3 Days",
        "icon" : phoneImg,
        "content" : <ul>
        <li>A verified third-party expert will call you to schedule your home assessment.</li>
        <li>The installation expert will verify home details and address installation process and costs queries.</li>
        </ul>
        
    },
    {
        "title" : "Home Assessment",
        "subHeading" : "Estimated 1-2 Weeks",
        "icon" : homeImg,
        "content" : <ul>
            <li>During the home assessment, a third-party expert will inspect and verify your home's details to confirm the cost of installation and unit.</li>
            <li>Upon agreement of all installation and unit costs, the third party expert assist in arranging financing options that fits your budget, pending credit checks and standard procedures.</li>
        </ul>    },
    {
        "title" : "Permitting & Installation",
        "subHeading" : "Estimated 12-14 Weeks",
        "icon" : hardhatImg,
        "content" : <ul>
            <li>Once financing is in place, the third-party installation expert will begin filing for required permits and work with you to schedule the installation.</li>
            <li>During the 1-2 day installation process, an expert will fully inspect the generator and provide you with a tutorial to ensure you understand its operation.  </li>
        </ul>
    },    
]

export const smbNextSteps = [
    {
        "title" : "Initial Phone Call",
        "subHeading" : "Estimated 1-3 Days",
        "icon" : phoneImg,
        "content" : <ul>
        <li>A verified third-party expert will call you to schedule your business assessment.</li>
        <li>The installation expert will verify business details and address installation process and costs queries.</li>
        </ul>
        
    },
    {
        "title" : "Business Assessment",
        "subHeading" : "Estimated 1-2 Weeks",
        "icon" : handImg,
        "content" : <ul>
            <li>During the business assessment, a third-party expert will inspect and verify your business' details to confirm the cost of installation and unit.</li>
            <li>Upon agreement of all installation and unit costs, the third party expert assist in arranging financing options that fits your budget, pending credit checks and standard procedures.</li>
        </ul>    
    },
    {
        "title" : "Permitting & Installation",
        "subHeading" : "Consult With Third Party Expert",
        "icon" : hardhatImg,
        "content" : <ul>
            <li>Once financing is in place, the third-party installation expert will begin filing for required permits and work with you to schedule the installation.</li>
            <li>During the 1-2 day installation process, an expert will fully inspect the generator and provide you with a tutorial to ensure you understand its operation.  </li>
        </ul>
    },    
]
import React from 'react';
import { AuthContext } from '../../context/authContext';
import { Navigate } from 'react-router-dom';
import { CircularProgress, Grid } from '@mui/material';
class OauthReceive extends React.Component {
    componentDidMount() {
        const auth = this.context;
        const params = new URLSearchParams(window.location.search);
        this.redirectUrl = params.get("state") ? params.get("state") : "/transferSwitch";

        auth.receiveLogin();
    }

    render() {
        const auth = this.context;
        
        if(auth.user)
            return (
            <Grid container item xs={12} justifyContent='center' style={{margin: 'auto'}}>
                <CircularProgress/>
                <Navigate to={this.redirectUrl}/>
            </Grid>
            );
            
        else if(auth.hasError) 
                return (
                    <div>
                        <div>Error retrieving User Information!</div>
                        { (auth.errorType === "access_denied") ?
                            <div>If you logged in using your full e-mail address, please re-login using only your Entergy username</div>
                            : <div>An unknown error occurred</div>
                        }
                    </div>)
        else
            return (<div>Fetching User Info...</div>);
    }
}

OauthReceive.contextType = AuthContext;
export default OauthReceive;

import { AuthContext } from '../../context/authContext';
import React from 'react';
import { CircularProgress, Grid } from '@mui/material';


class OauthSend extends React.Component {
    componentDidMount() {
        const auth = this.context;
        const params = new URLSearchParams(window.location.search);
        const redirect = params.get("redirect");

       window.location.replace(auth.getLoginUrl(redirect));
    }

    render() {
        return <Grid container item xs={12} justifyContent='center' style={{margin: 'auto'}}><CircularProgress/></Grid>;
    }
}

OauthSend.contextType = AuthContext;
export default OauthSend;

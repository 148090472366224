import { DataGrid } from "@mui/x-data-grid"
import { getSignups } from "../../../api/Admin";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router";
import './AdminTable.scss'
import StatusChip from "../StatusChip/StatusChip";
import { useQuery } from "react-query";
import toast from "react-hot-toast";

function AdminTable() {

  const navigate = useNavigate();

  const { data: signups, isFetching } = useQuery(['signups'], async () => { const { data } = await getSignups(); return data;}, 
    {
      retry: false,
      onError: (error) => {
        if(error.response?.status === 401)
          navigate("/unauthorized")
        else
          toast.error("There was an issue loading the signups")
      }
    }
  )

  const getFormattedDate = (val) => {
    const options = {
      year: "numeric",
      month:"2-digit",
      day:"2-digit",
      hour:  "2-digit",
      minute: "2-digit",
    };
    return new Date(val).toLocaleDateString("en-US", options).replace(', ','\n');
  }

  const getColumns = () => {
    return [
      {
        field: 'firstName', valueGetter: (value, row) => {return `${row.firstName || ''} ${row.lastName || ''}`;}, flex: 1,
        renderHeader: () => (<div className="data-grid-header">Name</div>),
        renderCell: (params) => (<div className="data-grid-cell">{params.value}</div>)
      },
      {
        field: 'opco', flex: 1,
        renderHeader: () => (<div className="data-grid-header">Operating Company</div>),
        renderCell: (params) => (<div className="data-grid-cell">{params.value}</div>)
      },
      {
        field: 'accountNumber', flex: 1,
        renderHeader: () => (<div className="data-grid-header">Account Number</div>),
        renderCell: (params) => (<div className="data-grid-cell">{params.value}</div>)
      },
      {
        field: 'device', flex: 1,
        renderHeader: () => (<div className="data-grid-header">Device</div>),
        renderCell: (params) => (<div className="data-grid-cell">{params.value}</div>)
      },
      {
        field: 'status', flex: 1,
        renderHeader: () => (<div className="data-grid-header">Status</div>),
        renderCell: (params) => (<div className="data-grid-cell"><StatusChip status={params.value}/></div>)
      },
      {
        field: 'createdDate', flex: 1,
        cellClassName: 'data-grid-cell',
        renderHeader: () => (<div className="data-grid-header">Submit Date</div>),
        renderCell: (params) => (<div className="data-grid-cell">{getFormattedDate(params.value)}</div>)
      }
    ]
  }

  return (
    isFetching ? 
      <CircularProgress className="spinner"/> 
      : 
      <DataGrid
        className="data-grid"
        rows={signups}
        columns={getColumns()}
        onRowClick={(params)=>navigate(`/transferSwitch/admin/${params.id}`)}
        getCellClassName={()=>'data-grid-cell'}
        initialState={{
          sorting: {
            sortModel: [{ field: 'createdDate', sort: 'desc' }],
          },
        }}
      />        
    )
}

export default AdminTable

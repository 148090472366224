import React from 'react';

export const getAfcTerms = (data) => {

    const { firstName, lastName, address, city, state, zipCode, selection } = data

    let totalAllocableCost, monthlyFacilitiesCharge, postRecoveryTermPayment, facilitiesChargeMonths, postRecoveryTermMonths;

    switch(selection){
        case '1 year':
            totalAllocableCost = '$1,106.32'
            monthlyFacilitiesCharge = '$75.04'
            facilitiesChargeMonths = '12'
            postRecoveryTermMonths = '72'
            postRecoveryTermPayment = '$2.86'
            break
        case '4 year':
            totalAllocableCost = '$1,275.32'
            monthlyFacilitiesCharge = '$24.43'
            facilitiesChargeMonths = '48'
            postRecoveryTermMonths = '36'
            postRecoveryTermPayment = '$2.86'
            break
        case '7 year':
            totalAllocableCost = '$1,463.27'
            monthlyFacilitiesCharge = '$17.42'
            facilitiesChargeMonths = '84'
            postRecoveryTermMonths = '0'
            postRecoveryTermPayment = 'N/A'
            break
        default:
            break
    }

    let date = new Date();

    return (
        <>
        <p style={{textAlign: 'right', margin: '0px'}}>AFC Agreement 1</p>
        <p style={{textAlign: 'right', margin: '0px'}}>Option B</p>

        <p style={{textAlign: 'center', fontWeight: 'bold', margin: '0px'}}>Additional Facilities Charge Agreement dated {date.toLocaleDateString("en-US", {month: "long", day:"2-digit", year: 'numeric'})}</p>
        <p style={{textAlign: 'center', fontWeight: 'bold', margin: '0px'}}>Between Entergy Louisiana, LLC (&quot;Company&quot;) and</p>
        <p style={{textAlign: 'center', fontWeight: 'bold', margin: '0px'}}>{lastName}, {firstName} (&quot;Customer&quot;)</p>
        <p style={{textAlign: 'center', fontWeight: 'bold', margin: '0px'}}>For Electric Service to Customer&apos;s Residence</p>
        <p style={{textAlign: 'center', fontWeight: 'bold', margin: '0px'}}>Located at {address}, {city}, {state} {zipCode}</p> 

        <p>This Additional Facilities Charge (&quot;AFC&quot;) Agreement 1 sets forth the application of the Company&apos;s currently effective Rate Schedule AFC, Option B as selected by Customer, to those electric facilities for which the Customer has contracted.  Rate Schedule AFC and the manner in which it is applied to those electric facilities for which the Customer has contracted are subject to change by the Company.</p>

        <p>The Monthly Facilities Charge amount contained in this AFC Agreement 1 is in addition to the charges for the customer&apos;s existing electrical services and applicable rate schedules.  Customer shall pay a monthly Facilities Charge to the Company, as described below and calculated pursuant to Rate Schedule AFC.</p>

        <p>This AFC Agreement 1 establishes a Total Allocable Cost of {totalAllocableCost} and a currently effective Monthly Facilities Charge of {monthlyFacilitiesCharge}.  This AFC Agreement 1 will be effective when the Company&apos;s facilities are installed (the &quot;Effective Date&quot;).  Beginning on the Effective Date, the Monthly Facilities Charge will be paid by customer every month for {facilitiesChargeMonths} months.  After the Customer has paid the {facilitiesChargeMonths} months of Monthly Facilities Charge payments, Customer will pay a monthly Post-Recovery Term payment of {postRecoveryTermPayment} per month for {postRecoveryTermMonths} months. This AFC Agreement 1 is subject to adjustment when future modifications, additions, or replacements are made to the facilities. This AFC Agreement 1 shall terminate seven years from the date of the Effective Date (the &quot;Termination Date&quot;).</p>

        <p>If Customer elects to cancel this AFC Agreement 1 prior to Termination Date, Customer will be responsible for any and all such termination costs as may be set forth in the Program Agreement.</p>

    </>
    )

}

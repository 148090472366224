import React, { useRef, useState } from 'react';
import './Home.scss';
import GenQuestions from '../../components/resiliency/GenQuestions/GenQuestions';
import FAQ from '../../components/resiliency/FAQ/FAQ';
import SMBForm from './SMB/SMBForm';
import GenSelection from '../../components/resiliency/GenSelection/GenSelection';
import { Route, Routes } from 'react-router-dom';
import Detail from './Detail';
import Confirm from './Confirm';
import { createLead } from '../../api/LeadAPI';
import { useNavigate } from 'react-router';
import { HomeCarousel } from '../../components/resiliency/HomeCarousel/HomeCarousel';
import { Alert, Snackbar } from '@mui/material';
import { faqEntries } from '../../constants/resiliency/faqEntries';
import { smbFaqEntries } from '../../constants/resiliency/smbFaqEntries';
import Banner from '../../components/Banner/Banner';
import Layout from '../../components/resiliency/Layout/Layout';

const LOAD_TIME = 1000;

export default function Home({props}) {
 
    const [suggestedGenerators, setSuggestedGenerators] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedGenerator, setSelectedGenerator] = useState(false);
    const [generatorQuestions, setGeneratorQuestions] = useState({ "selectedAppliances": [] });
    const [personalQuestions, setPersonalQuestions] = useState({"aptNumber" : "", "entergyEmployee": false});
    const [smbQuestions, setSmbQuestions] = useState({"type": "smb", "aptNumber" : ""});

    const [submitting, setSubmitting] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [residential, setResidential] = useState(true);

    const ref = useRef(null);
    
    const navigate = useNavigate();
    
    const setShowGenSelection = (genSuggestions) => {
        setLoading(true);
        setSuggestedGenerators(false);
        window.setTimeout(() => { 
            setSuggestedGenerators(genSuggestions);
            setSelectedGenerator(genSuggestions["bestFit"]);
            setLoading(false);
            ref.current.scrollIntoView(true);
        }, LOAD_TIME);
    }
    
    const setGeneratorSelection = (selGenerator) => {
        setSelectedGenerator(selGenerator);
    }
    
    const onSmbSubmit = (e) => {
        setResidential(false);
        setSubmitting(true);
        e.preventDefault();

        createLead(smbQuestions).then(() => {
            setSubmitting(false);
            navigate("/confirm");
        }).catch(() => {
            setSubmitting(false);
            setOpenError(true);
        });
    }

    const onSubmit = (e) => {
        setSubmitting(true);
        e.preventDefault();

        const leadInfo = {
            ...personalQuestions,
            ...generatorQuestions,
            "selectedGenerator" : {...selectedGenerator}
        }

        createLead(leadInfo).then(() => {
            setSubmitting(false);
            navigate("/confirm");
        }).catch(() => {
            setSubmitting(false);
            setOpenError(true);
        });
    }

    return (
        <>
            <Routes>
                <Route path="/" element={
                    <Layout>
                        <Banner program='resiliency' status='closed'/>
                        <HomeCarousel />
                        <GenQuestions loading={loading} setShowGenerators={setShowGenSelection} data={generatorQuestions} onDataChange={setGeneratorQuestions} />
                        <div ref={ref}>
                            <GenSelection loading={loading} suggestedGenerators={suggestedGenerators} selectedGenerator={selectedGenerator} onSelection={setGeneratorSelection} />
                        </div>
                        <FAQ data={faqEntries} />
                        <p>
                            <center>
                                *Installation estimates are based on an algorithm that assess inputs you provide. Actual costs may differ.
                            </center>
                        </p>
                    </Layout> 
                } />
                <Route path="/detail" element={ 
                    <Layout>
                        <Detail selectedGenerator={selectedGenerator} data={personalQuestions} onDataChange={setPersonalQuestions} onSubmit={onSubmit} loading={submitting} />
                        <FAQ data={faqEntries} />
                    </Layout>
                    } />
                    <Route path="/confirm" element={
                    residential ? 
                    <Layout>
                        <Confirm selectedGenerator={selectedGenerator} residential={true} />
                        <FAQ data={faqEntries} />
                    </Layout>
                    :
                    <Layout>
                        <Confirm selectedGenerator={selectedGenerator} residential={false} />
                        <FAQ data={smbFaqEntries} />
                    </Layout>         
                    } />
                <Route path="/smb" element={
                    <Layout>
                        <SMBForm data={smbQuestions} submitting={submitting} onDataChange={setSmbQuestions} onSubmit={onSmbSubmit} />
                    </Layout>
                } />
            </Routes>
            <Snackbar open={openError} autoHideDuration={6000} onClose={() => (setOpenError(false))}>
                <Alert onClose={() => (setOpenError(false))} severity="error">
                    There was an error saving your information. Please try again later. 
                </Alert>
            </Snackbar>
        </>
    );

}
import { Chip } from "@mui/material";
import { signupStatuses } from "../../../constants/transferSwitch/signupStatuses";
import './StatusChip.scss'

function StatusChip({status}) {
    
    const getStatusChip = (val, classes, isAgency=false) => {
        switch (val) {
          case (signupStatuses.APPROVED):
            return (<Chip variant="filled" className={`chip approved`} label="Approved" />);
          case (signupStatuses.DENIED):
            return (<Chip variant="filled" className={`chip denied`} label="Denied" />);
          case (signupStatuses.PENDING):
            return (<Chip variant="filled" className={`chip pending`} label="Pending" />);
          case (signupStatuses.ERROR):
            return (<Chip variant="filled" className={`chip error`} label="Error" />);
          case (signupStatuses.CANCELED):
            return (<Chip variant="filled" className={`chip canceled`} label="Canceled" />);
          case (signupStatuses.NEEDS_REVIEW):
            return (<Chip variant="filled" className={`chip needs-review`} label="Needs Review" />);
          default:
            return <></>;
        }
      }

    return (
        getStatusChip(status)
    )
}

export default StatusChip

import { useEffect, useContext } from "react";
import { Navigate, useParams } from "react-router"
import { AuthContext } from "../context/authContext";
import { useOpco } from "../context/opcoContext";
import Layout from "./transferSwitch/Layout/Layout";

export default function ProtectedRoute({children, isSharedPage}) {
    const { opco } = useParams();

    const auth = useContext(AuthContext)
    const { opcoData: { liveOpco, ssoRequired, showPageNotFound }, setOpco } = useOpco();
    
    useEffect(()=>{
      setOpco(opco)
    },[opco, setOpco])

    if (!auth.user && (isSharedPage || (liveOpco && ssoRequired))) {
      //If trying to hit backup.entergy.com/transferSwitch, redirect to https://transferswitch.keystringlabs.io/transferSwitch
      const host = window.location.host.toLowerCase();
      if (host === "backup.entergy.com") {
        const url = "https://transferswitch.keystringlabs.io{}".replace("{}", window.location.pathname);
        window.location.replace(url);
      } else {
        const url = "/login?redirect={}".replace("{}", window.location.pathname);
        return <Navigate to={url}></Navigate>;
      }
    }
    else {

    return (
        <Layout isSharedPage={isSharedPage} liveOpco={liveOpco} showPageNotFound={showPageNotFound}>
            {children}
        </Layout>
    )
    }
}


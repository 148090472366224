import { FormControl, RadioGroup, FormHelperText, Grid, Select, MenuItem } from '@mui/material';
import { StyledRadio } from '../../StyledRadio';

import './SignUpInfo.scss';
import { entergyCompanies } from '../../../constants/transferSwitch/entergyCompanies';

export default function SignUpInfo(props) {
    const { data, onDataChange, errors } = props;
    
    const handleSelect = (name, value) => {
        onDataChange({
            ...data,
            [name]: value
        });
    };

    const handleChange = (e) => {
        handleSelect(e.target.name, e.target.value);
    };

    const getErrorMessage = (...fieldNames) => {
        return fieldNames.map((item) => (getSingleError(item))).join(" ");
    }

    const getSingleError = (fieldName) => {
        if(fieldName === "compatibleGenerator")
            return errors[fieldName] ? "This field is required. " : errors["generatorInvalid"] ? "The 30-amp trifecta transfer switch is only compatible with a 7200 W continuous wattage generators or less." : " ";
        else if(fieldName === "entergyEmployee")
            return errors[fieldName] ? "This field is required. " : errors["notEntergyEmployee"] ? "This program is currently only available to Entergy employees." : " ";
        else
            return errors[fieldName] ? "This field is required. " : " ";
    }

    return (
        <>
        <main-container>
        <Grid container >
        <Grid item xs={12}>
                In order to verify your eligibility to participate in this pilot, we require your answers to the following questions:

                <h3>Information Details</h3>
            </Grid>
            <Grid item xs={12} className="question-column">
                <sign-up-question>  
                    <question-header>
                        1.  Are you an Entergy employee?
                    </question-header>
                    <FormControl required error={errors["entergyEmployee"] || errors["notEntergyEmployee"]}>
                        <RadioGroup name="entergyEmployee" value={data.entergyEmployee ?? ""} onChange={handleChange}>
                            <div><StyledRadio value="yes" />Yes</div>
                            <div><StyledRadio value="no" />No</div>
                        </RadioGroup>
                        <FormHelperText>{getErrorMessage("entergyEmployee")} </FormHelperText>
                    </FormControl>
                    {data["entergyEmployee"] && data["entergyEmployee"] === 'yes' &&
                        <>
                            <question-header>
                                Which Entergy company do you work for?
                            </question-header>
                            <FormControl error={errors["entergyCompany"]}> 
                                <Select
                                    name="entergyCompany"
                                    className="selectField"
                                    value={data.entergyCompany ?? ""}
                                    helperText={getErrorMessage("entergyCompany")}
                                    onChange={handleChange}>
                                        {entergyCompanies.map(option => (
                                            <MenuItem key={option} value={option}>{option}</MenuItem>
                                        ))}
                                </Select>
                                <FormHelperText>{getErrorMessage("entergyCompany")} </FormHelperText>
                             </FormControl>
                            </>
                    }
                </sign-up-question>  

                <sign-up-question>
                    <question-header>
                        2. Do you own a portable generator?
                    </question-header>
                    <FormControl required error={errors["ownPortableGenerator"]}>
                        <RadioGroup name="ownPortableGenerator" value={data.ownPortableGenerator ?? ""} onChange={handleChange}>
                            <div><StyledRadio value="yes" />Yes</div>
                            <div><StyledRadio value="no" />No</div>
                        </RadioGroup>
                        <FormHelperText>{getErrorMessage("ownPortableGenerator")} </FormHelperText>
                    </FormControl>
                </sign-up-question> 

                <sign-up-question>  
                    <question-header>
                        3.  
                        <a style={{fontWeight: '600'}}href='https://www.globalpowerproducts.com/wp-content/uploads/2024/01/How-to-choose-a-generator_FINAL.pdf' target="__blank">
                            {` The 30-amp trifecta transfer switch with surge protection device `}
                        </a>
                        is the only model available for this pilot. The 30-amp trifecta transfer switch with surge protection device is only compatible with a 7200 W continuous wattage generators or less. Is your generator 7200 continuous wattage or less? 
                    </question-header>
                    <FormControl required error={errors["compatibleGenerator"] || errors["generatorInvalid"]}>
                        <RadioGroup name="compatibleGenerator" value={data.compatibleGenerator ?? ""} onChange={handleChange}>
                            <div><StyledRadio value="yes" />Yes</div>
                            <div><StyledRadio value="no" />No</div>
                            <div><StyledRadio value="unsure" />Not Sure</div>
                        </RadioGroup>
                        <FormHelperText>{getErrorMessage("compatibleGenerator")} </FormHelperText>
                    </FormControl>
                </sign-up-question>  

                    <sign-up-question>
                        <question-header>
                            4. Do you know how to safely turn off and on the circuit breakers inside of your home’s electric panel? 
                        </question-header>
                        <FormControl required error={errors["circuitBreakers"]}>
                            <RadioGroup name="circuitBreakers" value={data.circuitBreakers ?? ""} onChange={handleChange}>
                                <div><StyledRadio value="yes" />Yes</div>
                                <div><StyledRadio value="no" />No</div>
                            </RadioGroup>
                            <FormHelperText>{getErrorMessage("circuitBreakers")} </FormHelperText>
                        </FormControl>
                    </sign-up-question>

                    <sign-up-question>
                        <question-header>
                            5. Do you have at least 10 feet of clearance next to your home’s outside electric panel? 
                        </question-header>
                        <FormControl required error={errors["tenFeetClearance"]}>
                            <RadioGroup name="tenFeetClearance" value={data.tenFeetClearance ?? ""} onChange={handleChange}>
                                <div><StyledRadio value="yes" />Yes</div>
                                <div><StyledRadio value="no" />No</div>
                            </RadioGroup>
                            <FormHelperText>{getErrorMessage("tenFeetClearance")} </FormHelperText>
                        </FormControl>
                    </sign-up-question>

                    {/* <sign-up-question>
                        <question-header>
                            6. Does your portable generator have a minimum of one (1) electrical outlet/ port (120 or 240 volt) 30 amp? 
                        </question-header>
                        <FormControl required error={errors["electricalOutlet"]}>
                            <RadioGroup name="electricalOutlet" value={data.electricalOutlet ?? ""} onChange={handleChange}>
                                <div><StyledRadio value="yes" />Yes</div>
                                <div><StyledRadio value="no" />No</div>
                            </RadioGroup>
                            <FormHelperText>{getErrorMessage("electricalOutlet")} </FormHelperText>
                        </FormControl>
                    </sign-up-question> */}

                    {/* <sign-up-question>
                        <question-header>
                            7. Please provide this information for your portable generator: 
                        </question-header>
                        <TextField 
                            id="portableGeneratorManufacturer" 
                            name="portableGeneratorManufacturer"
                            value={data.portableGeneratorManufacturer ?? ""}
                            onChange={handleChange}
                            error={errors["portableGeneratorManufacturer"]}
                            helperText={getErrorMessage("portableGeneratorManufacturer")}
                            label="Manufacturer" 
                            className="textfield"
                            />
                        <TextField 
                            id="portableGeneratorModelNumber" 
                            name="portableGeneratorModelNumber"
                            value={data.portableGeneratorModelNumber ?? ""}
                            onChange={handleChange}
                            error={errors["portableGeneratorModelNumber"]}
                            helperText={getErrorMessage("portableGeneratorModelNumber")}
                            label="Model Number" 
                            className="textfield"
                            />
                        <TextField 
                            id="portableGeneratorOtherInfo" 
                            name="portableGeneratorOtherInfo"
                            value={data.portableGeneratorOtherInfo ?? ""}
                            onChange={handleChange}
                            error={errors["portableGeneratorOtherInfo"]}
                            helperText={getErrorMessage("portableGeneratorOtherInfo")}
                            label="Other Distinctive Information" 
                             className="textfield"
                            />
                    </sign-up-question> */}

                    {/* <sign-up-question>
                        <question-header>
                            8. Is your electric panel rated at 125 amp or less?  
                        </question-header>
                        <FormControl required error={errors["electricPanelRate"]}>
                            <RadioGroup name="electricPanelRate" value={data.electricPanelRate ?? ""} onChange={handleChange}>
                                <div><StyledRadio value="yes" />Yes</div>
                                <div><StyledRadio value="no" />No</div>
                                <div><StyledRadio value="unsure" />Not Sure</div>
                            </RadioGroup>
                            <FormHelperText>{getErrorMessage("electricPanelRate")} </FormHelperText>
                        </FormControl>
                        <question-header>
                            If known, please indicate the size 
                        </question-header>
                        <TextField 
                            id="electricPanelSize" 
                            name="electricPanelSize"
                            value={data.electricPanelSize ?? ""}
                            onChange={handleChange}
                            error={errors["electricPanelSize"]}
                            helperText={getErrorMessage("electricPanelSize")}
                            label="Size" 
                            className="textfield"
                             />
                    </sign-up-question> */}
                    {/* <sign-up-question>
                        <question-header>
                            9. Have you experienced a planned outage event within the past 12 months?
                        </question-header>
                        <FormControl required error={errors["pspsOutage"]}>
                            <RadioGroup name="pspsOutage" value={data.pspsOutage ?? ""} onChange={handleChange}>
                                <div><StyledRadio value="yes" />Yes</div>
                                <div><StyledRadio value="no" />No</div>
                                <div><StyledRadio value="unsure" />Not Sure</div>
                            </RadioGroup>
                            <FormHelperText>{getErrorMessage("pspsOutage")} </FormHelperText>
                        </FormControl>
                    </sign-up-question> */}

                    <sign-up-question>
                        <question-header>
                            6. Are you able to safely position your generator in the vicinity of your electric panel and connect the electric cable from the generator to the meter mounted adapter? 
                        </question-header>
                        <question-header>
                            (Note: Entergy will demonstrate the proper way to connect the generator as part of the installation procedure.) 
                        </question-header>
                        <FormControl required error={errors["safetyPositionGenerator"]}>
                            <RadioGroup name="safetyPositionGenerator" value={data.safetyPositionGenerator ?? ""} onChange={handleChange}>
                                <div><StyledRadio value="yes" />Yes</div>
                                <div><StyledRadio value="no" />No</div>
                            </RadioGroup>
                            <FormHelperText>{getErrorMessage("safetyPositionGenerator")} </FormHelperText>
                        </FormControl>
                    </sign-up-question>

                    <sign-up-question>
                        <question-header>
                            7. Are you the owner of the residence of where the Meter Mounted Adapter is to be installed?  
                        </question-header>
                        <FormControl required error={errors["ownerOfResidence"]}>
                            <RadioGroup name="ownerOfResidence" value={data.ownerOfResidence ?? ""} onChange={handleChange}>
                                <div><StyledRadio value="yes" />Yes</div>
                                <div><StyledRadio value="no" />No</div>
                            </RadioGroup>
                            <FormHelperText>{getErrorMessage("ownerOfResidence")} </FormHelperText>
                        </FormControl>
                    </sign-up-question>

                    {/* <sign-up-question>
                        <question-header>
                            12. Is this a single - family residence? 
                        </question-header>
                        <FormControl required error={errors["singleFamilyResidence"]}>
                            <RadioGroup name="singleFamilyResidence" value={data.singleFamilyResidence ?? ""} onChange={handleChange}>
                                <div><StyledRadio value="yes" />Yes</div>
                                <div><StyledRadio value="no" />No</div>
                            </RadioGroup>
                            <FormHelperText>{getErrorMessage("singleFamilyResidence")} </FormHelperText>
                        </FormControl>
                    </sign-up-question> */}

                    {/* <sign-up-question>
                        <question-header>
                            13. Is the Entergy electrical service account listed in your name? 
                        </question-header>
                        <FormControl required error={errors["isAccountOwner"]}>
                            <RadioGroup name="isAccountOwner" value={data.isAccountOwner ?? ""} onChange={handleChange}>
                                <div><StyledRadio value="yes" />Yes</div>
                                <div><StyledRadio value="no" />No</div>
                            </RadioGroup>
                            <FormHelperText>{getErrorMessage("isAccountOwner")} </FormHelperText>
                        </FormControl>
                        {data["isAccountOwner"] && data["isAccountOwner"] === 'no' &&
                            <>
                            <question-header>
                                If not, in whose name is the service account listed and what is their relationship to you?
                            </question-header>
                            <TextField 
                                autoComplete="no"
                                id="accountOwnerName" 
                                name="accountOwnerName"
                                value={data.accountOwnerName ?? ""}
                                onChange={handleChange}
                                error={errors["accountOwnerName"]}
                                helperText={getErrorMessage("accountOwnerName")}
                                label="Account Owner Name" 
                                fullWidth />
                            <TextField 
                                autoComplete="no"
                                id="accountOwnerRelationship" 
                                name="accountOwnerRelationship"
                                value={data.accountOwnerRelationship ?? ""}
                                onChange={handleChange}
                                error={errors["accountOwnerRelationship"]}
                                helperText={getErrorMessage("accountOwnerRelationship")}
                                label="Relationship to Account Owner" 
                                fullWidth />
                            </>
                        }
                    </sign-up-question> */}

                    {/* <sign-up-question>
                        <question-header>
                            14. Is your electric panel located inside or outside of your house? 
                        </question-header>
                        <FormControl required error={errors["panelLocation"]}>
                            <RadioGroup name="panelLocation" value={data.panelLocation ?? ""} onChange={handleChange}>
                                <div><StyledRadio value="inside" />Inside</div>
                                <div><StyledRadio value="outside" />Outside</div>
                            </RadioGroup>
                            <FormHelperText>{getErrorMessage("panelLocation")} </FormHelperText>
                        </FormControl>
                        {data["panelLocation"] && data["panelLocation"] === 'inside' &&
                        <>
                        <question-header>
                            If inside, is there a door or window opening within 3 feet of the electric panel?
                        </question-header>
                        <FormControl error={errors["doorOrWindow"]}>
                            <RadioGroup name="doorOrWindow" value={data.doorOrWindow ?? ""} onChange={handleChange}>
                                <div><StyledRadio value="yes" />Yes</div>
                                <div><StyledRadio value="no" />No</div>
                            </RadioGroup>
                            <FormHelperText>{getErrorMessage("doorOrWindow")} </FormHelperText>
                        </FormControl>
                        </>}
                    </sign-up-question> */}
            </Grid>            
        </Grid>
        </main-container>
        </>
    )
    
  }

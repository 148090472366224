import { Grid } from "@mui/material";
import "./LandingPage.scss";
import image from '../../assets/ts-image1.jpeg';
import OpcoButton from "../../components/transferSwitch/OpcoButton/OpcoButton";
import louisiana from '../../assets/louisiana-logo.png';
// import texas from '../../assets/texas-logo.png';
// import arkansas from '../../assets/arkansas-logo.png';
// import mississippi from '../../assets/mississippi-logo.png';
// import neworleans from '../../assets/neworleans-logo.png';


export default function LandingPage() {

  return (
    <Grid container>
      <landing-page-main>
        <landing-page-image>
          <img src={image} alt='TS' className="headingImg" />
        </landing-page-image>
        <landing-page-window>
          <landing-page-title>Participate in the Transfer Switch with Surge Protection Pilot Program</landing-page-title>
            <div>
              The transfer switch with surge protection device is a UL-listed and socket-mounted transfer switch, designed to offer a safe and convenient solution for connecting portable generator power to your home within minutes.  
              With surge protection, there's no need for a manual transfer switch. It is installed behind your electric meter and facilitates the direct delivery of generator power to your breaker box, eliminating the inconvenience of running power cords through your home. Additionally, this device serves as a reliable surge protector, keeping your electrical system safe. 
              <br/><br/>
              Select your location to learn more.
            </div>
          </landing-page-window>
      </landing-page-main>
      <landing-page-opcos>
        <landing-page-subtitle>
          The Transfer Switch with Surge Protection Pilot Program is currently open in these locations:
        </landing-page-subtitle>
        
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <OpcoButton url='/transferSwitch/louisiana' image={louisiana}>
          Louisiana
          </OpcoButton>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={3} lg={2}>
        <OpcoButton url='/transferSwitch/texas' image={texas}>
          Texas
          </OpcoButton>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
        <OpcoButton url='/transferSwitch/mississippi' image={mississippi}>
          Mississippi
          </OpcoButton>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
        <OpcoButton url='/transferSwitch/mississippi' image={arkansas}>
          Arkansas
          </OpcoButton>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
        <OpcoButton url='/transferSwitch/mississippi' image={neworleans}>
          New Orleans 
          </OpcoButton>
        </Grid> */}

      </landing-page-opcos>
    </Grid>
  );
}

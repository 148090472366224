import AdminTable from "../../components/admin/AdminTable/AdminTable"

function Admin() {
    return (
        <div>
            <AdminTable/>
        </div>
    )
}

export default Admin

import { useParams } from "react-router";
import SignupInfo from "../../components/admin/SignupInfo/SignupInfo"

function AdminSignupInfo() {
    const { signupId } = useParams();

    return (
        <div>
            <SignupInfo signupId={signupId}/>
        </div>
    )
}

export default AdminSignupInfo

import { API } from "./API"

const getSignups = () => {
    return API.get('admin/signups')
}

const getSignup = (id) => {
    return API.get(`admin/signups/${id}`)
}

const updateStatus = (id, status) => {
    return API.patch(`admin/signups/${id}/status`, {newStatus: status})
}

export {
    getSignups,
    getSignup,
    updateStatus
};
  
import fanImg from '../../assets/icon-fan-pink.png';
import lightImg from '../../assets/icon-light-pink.png';
import fridgeImg from '../../assets/icon-refrigerator-pink.png';
import wifiImg from '../../assets/icon-wifi-pink.png';
import airImg from '../../assets/icon-air-pink.png';
import freezerImg from '../../assets/icon-freezer-pink.png';
import ovenImg from '../../assets/icon-oven-pink.png';
import dryerImg from '../../assets/icon-dryer-pink.png';
import wellImg from '../../assets/icon-well-pink.png';
import evImg from '../../assets/icon-ev-pink.png';
import poolImg from '../../assets/icon-pool-pink.png';
import medImg from '../../assets/icon-med-pink.png';


export const appliances = [
    {
        "name" : "Central Air",
        "heading": "appliances",
        "icon": airImg,
        "value" : 2000
    },
    {
        "name" : "Central Heat (Electric)",
        "heading" : "appliances",
        "icon" : airImg,
        "value" : 700
    },
    {
        "name" : "Dryer (Electric)",
        "heading" : "appliances",
        "icon": dryerImg,
        "value": 738
    },
    {
        "name" : "Oven (Electric)",
        "heading": "appliances",
        "icon" : ovenImg,
        "value" : 700
    },
    {
        "name" : "Water Heater (Electric)",
        "heading" : "appliances",
        "icon" : freezerImg,
        "value" : 700
    },
    {
        "name" : "Electric Vehicle",
        "heading" : "other",
        "icon" : evImg,
        "value" : 2000
    },
    {
        "name" : "Medical Equipment",
        "heading" : "other",
        "icon" : medImg,
        "value" : 400
    },
    {
        "name" : "Pool Pump",
        "heading" : "other",
        "icon" : poolImg,
        "value" : 300
    },
    {
        "name" : "Sump Pump",
        "heading" : "other",
        "icon" : wellImg,
        "value" : 500
    },
    {
        "name" : "Well Pump",
        "heading" : "other",
        "icon" : wellImg,
        "value" : 1000
    },
    {
        "name" : "Lights",
        "heading": "essentials",
        "icon": lightImg,
        "value" : 0
    },
    {
        "name" : "Portable Fan",
        "heading" : "essentials",
        "icon": fanImg,
        "value" : 0
    },
    {
        "name" : "Refrigerator",
        "heading" : "essentials",
        "icon": fridgeImg,
        "value" : 0
    },

    {
        "name" : "WiFi",
        "heading": "essentials",
        "icon": wifiImg,
        "value" : 0
    }
];
import React from 'react';

export const homeBenefits = [
    {
        "name" : "Easy access to power",
        "description" : 
        <>
            <p>You gain easy access to your generator without the need for complicated rewiring. Our expert team will install the device outside near your electric meter.</p>
        </>,

        "cardID" : 1
    },
    {
        "name" : "Choose what to power",
        "description" : 
        <>
            <p>You get to choose which appliances to power from your breaker panel to match your generator. Unlike basic switches limited to 6 to 8 hard-wired circuits, this device allows you to connect more circuits.</p>
        </>,
        "cardID" : 2
    },
    {
        "name" : "Instantly run large appliances",
        "description" : 
        <>
            <p>You get the flexibility to link to your breaker panel, enabling you to power large appliances like your water pump, water heater, and more with a simple switch, eliminating the need for cords.</p>
        </>,
        "cardID" : 3
    },
    {
        "name" : "Surge protection",
        "description" : 
        <>
            <p>Your appliances stay safe and protected from power spikes. It beeps if there's a surge, so your appliances are secured.</p>
        </>,
        "cardID" : 4
    }
];

import React from 'react';
import image from '../../assets/ts-image1.jpeg';
import './Confirm.scss';

export default function Confirm() {
    
    return (
        <>
            <grid-container>
                <grid-row-heading>
                    <grid-item-half>
                        <img src={image} alt="Generator" className="headingImg"/>
                    </grid-item-half>
                    <grid-item-half>
                        <confirm-title>
                            <h1 className="bold">Thank You</h1>
                        </confirm-title>
                        <detail-content>
                            A pilot program administrator will verify your eligibility and provide you with a contractual agreement to take part in the program.
                        </detail-content>
                    </grid-item-half>
                </grid-row-heading>
            </grid-container>
        </>
    );

}
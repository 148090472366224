import React, { useRef, useState } from 'react';
import PersonalInfo from '../../components/resiliency/PersonalInfo/PersonalInfo';
import generatorImg from '../../assets/gen-brick-house.jpg';
import GenDetail from '../../components/resiliency/GenDetail/GenDetail';
import { StyledButton } from '../../components/StyledButton';
import { Link } from 'react-router-dom';
import './Detail.scss';
import { CircularProgress } from '@mui/material';

export default function Detail(props) {

    const { selectedGenerator, data, onDataChange, onSubmit, loading} = props;
    const [errors, setErrors ] = useState({});
        
    const requiredValues = ["firstName", "lastName", "email", "phoneNumber", "virtualConsult", "timeToReach", "address", "propertyZip", "city", "state", "agreeToTerms", "agreeToSub", "agreeToAddlTerms"];
    const ref = useRef(null);
    const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:|\\)*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:|\\)+)\])/;
    const phoneRegex = /^\(?\d{3}[.)-]?\s*\d{3}[-.\s]?\d{4}$/;

    const formValidate = (inData, reqValues) => {
        
        let errs = {};

        reqValues.forEach((item) => {
            errs[item] = (!inData[item]) ? true : false;
        });

        errs["phoneInvalid"] = phoneRegex.test(inData["phoneNumber"]) ? false: true;
        errs["emailInvalid"] = emailRegex.test(inData["email"]) ? false : true;

        setErrors({ ...errs });

        return Object.values(errs).every((item) => (item === false));
    }

    const onFormSubmit = (e) => {
        e.preventDefault();

        if(formValidate(data, requiredValues)) {
            onSubmit(e);
        }
        else {
            ref.current.scrollIntoView();
        }
    }

    return (
        <>
            <grid-container>
                <grid-row-heading>
                    <grid-item-half>
                        <img src={generatorImg} alt="Generator" className="headingImg"/>
                    </grid-item-half>
                    <grid-item-half>
                        <detail-heading>
                            <h2 className="pink-highlight bold">Schedule a Free Home Assessment</h2>
                        </detail-heading>
                        <detail-content>
                        Fill out your information below to be connected to a verified third-party expert. They will schedule an in-home or virtual home assessment to confirm your power needs, provide a quote, and answer any questions about the installation process.
                        </detail-content>
                    </grid-item-half>
                </grid-row-heading>
            </grid-container>
            <form id="frmDetails" onSubmit={onFormSubmit} ref={ref}>
                <PersonalInfo data={data} onDataChange={onDataChange} errors={errors} />
                <grid-container>
                    <grid-row>
                        <grid-item-full>
                            <center>
                            <h2>Review Selected Generator</h2>
                            </center>
                        </grid-item-full>
                    </grid-row>
                    {selectedGenerator ? <GenDetail type="bestFit" item={selectedGenerator} /> : "" }
                    <grid-row>
                        <grid-item-full>
                            <center>
                                { loading ? 
                                    <CircularProgress size={'2.6rem'} /> 
                                    :
                                    <StyledButton variant="contained" type="submit" disabled>Submit</StyledButton>
                                }
                        </center>
                        </grid-item-full>
                    </grid-row>
                    <grid-row>
                        <grid-item-full>
                            <center><Link to="/">Go Back</Link></center>
                        </grid-item-full>
                    </grid-row>
                </grid-container>
            </form>
        </>
    );

}
import React, { useRef, useState } from 'react';

import generatorImg from '../../../assets/gen-open.jpg';
import { allowedZipCodes } from '../../../constants/resiliency/allowedZipCodes';
import { StyledButton } from '../../../components/StyledButton';

import './SMBForm.scss';
import { CircularProgress } from '@mui/material';
import SMBInfo from '../../../components/resiliency/SMBInfo/SMBInfo';
import FAQ from '../../../components/resiliency/FAQ/FAQ';
import { smbFaqEntries } from '../../../constants/resiliency/smbFaqEntries';
import Banner from '../../../components/Banner/Banner';

export default function SMBForm(props) {

    const { submitting, onSubmit, data, onDataChange } = props;
    const [errors, setErrors ] = useState({});

    const requiredValues = ["businessName", "firstName", "lastName", "jobTitle", "rentOrOwn", "authorized", "email", "phoneNumber", "address", "zipCode", "city", "state", "agreeToTerms", "agreeToSub", "agreeToAddlTerms", "serviceZip"];
    const ref = useRef(null);
    const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:|\\)*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:|\\)+)\])/;
    const phoneRegex = /^\(?\d{3}[.)-]?\s*\d{3}[-.\s]?\d{4}$/;

    const formValidate = (inData, reqValues) => {
        
        let errs = {};

        reqValues.forEach((item) => {
            errs[item] = (!inData[item]) ? true : false;
        });

        errs['zipError'] = allowedZipCodes.find((item) => (item === inData.serviceZip)) ? false : true;
        errs["phoneInvalid"] = phoneRegex.test(inData["phoneNumber"]) ? false: true;
        errs["emailInvalid"] = emailRegex.test(inData["email"]) ? false : true;

        setErrors({ ...errs });

        return Object.values(errs).every((item) => (item === false));
    }

    const onFormSubmit = (e) => {

        e.preventDefault();
        if(formValidate(data, requiredValues)) {
            onSubmit(e);
        }
        else {
            ref.current.scrollIntoView();
        }
    }

    return (
        <>
            <content-area class="extra-padding-2">
                <Banner program='resiliency' status='closed'/>
                <grid-container>
                    <grid-row-heading>
                        <grid-row-full>
                            <h1 className="pink-highlight bold">Invest in your business today with Entergy's Backup Generator Program</h1>
                        </grid-row-full>
                    </grid-row-heading>
                    <grid-row-heading>
                        <grid-item-half>
                            <img src={generatorImg} alt="Generator" className="headingImg"/>
                        </grid-item-half>
                        <grid-item-half>
                            <detail-heading>
                                <h2 className="blue-highlight bold">Invest in the safety and security of your business today!</h2>
                            </detail-heading>
                            <detail-content>
                            With Entergy’s backup generator program, you can have peace of mind knowing that your business will stay operational during even the toughest weather.  A standby generator automatically turns on during an outage to power your business without having to deal with any extension cords or manual fueling. Size it to power your entire business, from HVAC system to essential appliances like refrigerators, lights, and internet. This ensures peace of mind during any outage.                            </detail-content>
                        </grid-item-half>
                    </grid-row-heading>
                </grid-container>
            </content-area>
            <highlight-area class="background-highlight extra-padding-1 align-header">
                <content-area>
                    <grid-container>
                        <grid-row>
                            <grid-item-third>
                                <card-heading>Apply Online</card-heading>
                                To check your eligibility for discounts through the program, simply fill out the form below and learn more. Our team will review your business' electricity usage to determine whether you're eligible for a discounted generator, and let you know the result by email.
                            </grid-item-third>
                            <grid-item-third>
                                <card-heading>Eligibility Determination</card-heading>
                                If your business meets the qualification requirements, we will forward your information to an experienced generator expert who will initiate the sales and installation process.
                            </grid-item-third>
                            <grid-item-third>
                                <card-heading>Expert Help & Support</card-heading>
                                The third-party generator expert will take it from there. Our team will be available to answer any questions and provide guidance every step of the way. 
                            </grid-item-third>
                        </grid-row>
                    </grid-container>
                </content-area>
            </highlight-area>
            <content-area class="extra-padding-2">
                <form id="frmDetails" onSubmit={onFormSubmit} ref={ref}>
                    <SMBInfo data={data} errors={errors} onDataChange={onDataChange} />
                    <grid-container>
                        <grid-row>
                            <grid-item-full>
                                <center>
                                    { submitting ? 
                                        <CircularProgress size={'2.6rem'} /> 
                                        :
                                        <StyledButton variant="contained" type="submit" disabled>Submit</StyledButton>
                                    }
                            </center>
                            </grid-item-full>
                        </grid-row>
                    </grid-container>
                </form>
                <FAQ data={smbFaqEntries} />
            </content-area>
        </>
    );

}
import { useOpco } from "../../context/opcoContext";
import "./TopNav.scss";

export default function TopNav() {

    const { opcoData: { opcoDisplayOfficial, opcoLink } } = useOpco()

    const url = opcoLink || 'https://www.entergy.com/';

    return (
        <nav className='topNav'>
            <a href={url} className='topNav-link'>
                {opcoDisplayOfficial || 'Entergy '}
            </a>
        </nav>
    )
}


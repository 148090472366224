import React from 'react';
import { StyledButtonSentenceCase } from '../../components/StyledButton';
import './Safety.scss';
import { useNavigate } from 'react-router';
import { useOpco } from '../../context/opcoContext';

export default function Safety() {
    const navigate = useNavigate();
    const { opco } = useOpco();

    return (
            <>
                <grid-container>
                    <grid-row-heading class="align-header">
                        <grid-row-full>
                            <h1 className="bold">Entergy Transfer Switch with Surge Protection Pilot Program Safety Tips</h1>

                            {`To ensure optimal performance and reliability, carefully follow the instructions provided by the transfer switch with surge protection device manufacturer and the meter technician when operating the device. `}
                            <a className="safety-link" href="https://generlink.com/files/134351750.pdf" target="_blank" rel="noreferrer">Download guide on how to safely connect your portable generator. </a> 
                            <br/>
                            <br/>
                            Additionally, follow the additional minimum safety requirements:
                        </grid-row-full>
                    </grid-row-heading>
                    <grid-row-heading>
                        <detail-content class="details">
                            <detail-header>
                                Installation guidelines:  
                            </detail-header>
                            <ul>
                                <li>
                                    Your generator must be at least 18 inches (1.5 feet) away from your home or business.      
                                </li>
                                <li>
                                    Your generator must be at least 60 inches (5 feet) away from all doors, windows, and fresh air intakes.   
                                </li>
                            </ul>
                            <detail-header>
                                Power Cord usage:  
                            </detail-header>
                            <ul>
                                <li>
                                    Before using the power cord, check for exposed wires and/or frayed insulation.      
                                </li>
                                <li>
                                    Store the power cord in a dry and secure location, when not in use.  
                                </li>
                                <li>
                                    Place the power cord in a secure location, away from potential damage sources like lawn mowers, power tools, or vehicles.  
                                </li>
                                <li>
                                    Do not plug the power cord into the transfer switch with surge protection device if the red fault status light is continuously on.  
                                </li>
                                <li>
                                    Avoid connecting or disconnecting the power cord to/from your portable generator while the generator is running. 
                                </li>
                            </ul>
                            <detail-header>
                                Safety precautions: 
                            </detail-header>
                            <ul>
                                <li>
                                    Turn off both the portable generator and all circuits in your breaker panel before connecting or disconnecting the power cord.    
                                </li>
                                <li>
                                    Only activate electric panel breakers up to the maximum amperage of the portable generator as per the owner’s manual.  
                                </li>
                                <li>
                                    Do not attempt to alter or repair the device since it has been installed.  
                                </li>
                                <li >
                                    Contact Entergy immediately if there is any damage to the transfer switch with surge protection device. For emergency assistance, customers may call 1-800-9OUTAGE. 
                                </li>
                            </ul>
                        </detail-content>
                    </grid-row-heading>
                    <grid-row-full class="button">
                        <StyledButtonSentenceCase disabled variant="contained" onClick={() => navigate(`/transferSwitch/${opco}/signUp`)}>Sign up today</StyledButtonSentenceCase>
                    </grid-row-full>
                </grid-container>
            </>
    );

}
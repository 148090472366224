import { Close } from "@mui/icons-material";
import { Chip, styled } from "@mui/material";
import React from "react";

const StyledChip = styled(Chip) ({
    margin: "0.5rem", 
    fontFamily: "proxima-nova, sans-serif",
    backgroundColor: "#FDFBFF",
    color: "#000000",
    borderRadius: "8px",
    border: "1px #74777F solid",
    "&:hover": {
        border: "1px #AD0047 solid",
        backgroundColor: "#FDFBFF",
        color: "#AD0047"
    }
});

const FocusedChip = styled(Chip) ({
    margin: "0.5rem", 
    fontFamily: "proxima-nova, sans-serif",
    backgroundColor: "rgba(255, 26, 88, 0.3)",
    color: "#000000",
    borderRadius: "8px",
    border: "1px #74777F solid",
    "&:hover": {
        border: "1px #AD0047 solid",
        backgroundColor: "rgba(255, 26, 88, 0.3)",
        color: "#AD0047"
    }
});

export function ClickChip(props){
    return (
        props.checked? 
            <FocusedChip deleteIcon={ <Close /> } onDelete={props.onChipClick } {...props} />
            : <StyledChip onClick={props.onChipClick} {...props} />
    )
}
import { Button, styled } from "@mui/material"

export const StyledButton = styled(Button)({
  color: "#ffffff",
  backgroundColor: "#FF1A58",
  borderRadius: "100px",
  padding: "0.5rem 2rem",
  border: "2px solid #FF1A58",
  fontWeight: "600",
  fontFamily: "proxima-nova, sans-serif",
  textDecoration: "none",
  height: '3rem',
  "&:visited" : {
    color: "#ffffff",
    textDecoration: "none"
  },
  "&:hover": {
      border: "2px solid #FF1A58",
      backgroundColor: "#FFFFFF",
      boxShadow: "0px 1px 2px rgba(0,0,0,0.3), 0px 1px 3px 1px rgba(0,0,0,0.15)",
      color: "#FF1A58"
  },
  "&:active": {
      border: "2px solid #AD0047",
      background: "#AD0047",
      color: "#ffffff"
  },
  "&:disabled": {
      background: "#B0B0B0",
      border: "2px solid rgba(0,0,0,0.38)",
      color: "rgba(0,0,0,0.38)"
  }
});

export const StyledButtonSentenceCase = styled(Button)({
  color: "#ffffff",
  backgroundColor: "#FF1A58",
  borderRadius: "100px",
  padding: "0.5rem 3rem",
  border: "2px solid #FF1A58",
  fontWeight: "700",
  fontSize: '14px',
  fontFamily: "proxima-nova, sans-serif",
  textDecoration: "none",
  textTransform: "none",
  height: 'auto',
  "&:visited" : {
    color: "#ffffff",
    textDecoration: "none"
  },
  "&:hover": {
      border: "2px solid #FF1A58",
      backgroundColor: "#FFFFFF",
      boxShadow: "0px 1px 2px rgba(0,0,0,0.3), 0px 1px 3px 1px rgba(0,0,0,0.15)",
      color: "#FF1A58"
  },
  "&:active": {
      border: "2px solid #AD0047",
      background: "#AD0047",
      color: "#ffffff"
  },
  "&:disabled": {
      background: "#B0B0B0",
      border: "2px solid rgba(0,0,0,0.38)",
      color: "rgba(0,0,0,0.38)"
  }
});

export const StyledButtonOutlined = styled(Button)({
  backgroundColor: "#ffffff",
  color: "#FF1A58",
  borderRadius: "100px",
  padding: "0.5rem 2rem",
  border: "2px solid #FF1A58",
  fontWeight: "700",
  fontSize: '14px',
  fontFamily: "proxima-nova, sans-serif",
  textDecoration: "none",
  textTransform: "none",
  height: '3rem',
  "&:visited" : {
    color: "#ffffff",
    textDecoration: "none"
  },
  "&:hover": {
      border: "2px solid #FF1A58",
      backgroundColor: "#FF1A58",
      boxShadow: "0px 1px 2px rgba(0,0,0,0.3), 0px 1px 3px 1px rgba(0,0,0,0.15)",
      color: "#FFFFFF"
  },
  "&:active": {
      border: "2px solid #AD0047",
      background: "#AD0047",
      color: "#FF1A58"
  },
  "&:disabled": {
      background: "#B0B0B0",
      border: "2px solid rgba(0,0,0,0.38)",
      color: "rgba(0,0,0,0.38)"
  }
});

import { styled } from "@mui/material";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
export const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} enterTouchDelay={0} classes={{ popper: className}} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#fefefe',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      padding: '0.75rem',
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.25)',
      borderRadius: '10px'
    },
  }));
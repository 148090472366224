import './GenDetail.scss';
import ribbonImg from '../../../assets/icon-ribbon.svg';
import pinkRibbonImg from '../../../assets/icon-ribbon-pink.svg';
import iconLightbulb from '../../../assets/icon-lightbulb-green.svg';
import iconGlobe from '../../../assets/icon-planet.svg';
import iconHardhat from '../../../assets/icon-hardhat-orange.svg';
import iconTarget from '../../../assets/icon-target.svg';
import iconHomeBlue from '../../../assets/icon-home-blue.svg';
import iconHomeOrange from '../../../assets/icon-home-orange.svg';
import iconPhoneOrange from '../../../assets/icon-phone-orange.svg';
import { IconButton } from '@mui/material';
import { StyledTooltip } from '../../StyledTooltip';
import { InfoOutlined } from '@mui/icons-material';
import React from 'react';

export default function GenDetail(props) {
    const { item } = props;

    const getChoiceType = (itemType) => {
        switch(itemType) {
            case "bestFit" : return "Best Fit";
            case "budget" : return "Budget Choice";
            case "upgrade" : return "Upgrade";
            default : return "Best Fit";
        }
    } 
    
    return (
        <>
            <gen-detail>
                <detail-column>
                    <detail-row>
                        <detail-item-badge>
                            <img src={ (item.selectionType === "bestFit" ? pinkRibbonImg : ribbonImg )} width="30" height="40" alt="Ribbon" />
                        </detail-item-badge>
                        <detail-item-selection class={ (item.selectionType === "bestFit" ? "pink-highlight" : "")}>
                        
                            { getChoiceType(item.selectionType) }
                        
                        </detail-item-selection>
                    </detail-row>
                    <detail-row class="detail-header">
                        <detail-item-full>
                            <div className="large-font bold">{item.name}</div>
                            <div className="bold">{item.description}</div>
                            <small-font>200 Amp SER Transfer Switch Included</small-font>
                            <small-font><a target="__blank" href={item.spec}>Download Spec Sheet</a></small-font>
                        </detail-item-full>
                    </detail-row>
                    <detail-row>
                        <detail-item-full>
                            <center><img src={item.image} width="150px" alt="Generator" /></center>
                        </detail-item-full>
                    </detail-row>
                    <detail-row>
                        <detail-item-full class="bold">
                            Quick Specs
                        </detail-item-full>
                    </detail-row>
                    <small-font>
                        <detail-row class="highlight">
                            <detail-item-left>
                                Model Number
                            </detail-item-left>
                            <detail-item-right>
                                {item.model}
                            </detail-item-right>
                        </detail-row>
                        <detail-row>
                            <detail-item-left>
                                Watts
                            </detail-item-left>
                            <detail-item-right>
                                {item.watts}
                            </detail-item-right>
                        </detail-row>
                        <detail-row class="highlight">
                            <detail-item-left>
                                Engine Type
                            </detail-item-left>
                            <detail-item-right>
                                {item.engineType}
                            </detail-item-right>
                        </detail-row>
                        <detail-row>
                            <detail-item-left>
                                Fuel Type
                            </detail-item-left>
                            <detail-item-right>
                                {item.fuelType}
                            </detail-item-right>
                        </detail-row>
                        <detail-row class="highlight">
                            <detail-item-left>
                                Enclosure
                            </detail-item-left>
                            <detail-item-right>
                                {item.enclosure}
                            </detail-item-right>
                        </detail-row>
                        <detail-row >
                            <detail-item-left>
                            Controller Type
                            </detail-item-left>
                            <detail-item-right>
                                {item.controllerType}
                            </detail-item-right>
                        </detail-row>
                    </small-font>
                </detail-column>

                <detail-column class="center-column-padding">
                    <detail-row class="detail-header sub-column">
                        <detail-item-full>
                            <div>
                                <large-font class="bold">
                                    ${item.financing132} - {item.financing18} per month
                                </large-font>
                                <StyledTooltip arrow title={
                                    <React.Fragment>
                                        Financing may vary. Estimates are based on financing options provided by Synchrony. <a href="#faq">View FAQs</a>
                                    </React.Fragment>
                                    }>
                                    <IconButton style={{padding: '0px'}}>
                                        <InfoOutlined />
                                    </IconButton>
                                </StyledTooltip> 
                            </div>
                            <small-font>with financing</small-font>   
                        </detail-item-full>
                    </detail-row>
                    <detail-row class="bold">
                        <detail-item-left>
                           Generator Cost:
                        </detail-item-left>
                        <detail-item-right>
                            ${item.cost}
                        </detail-item-right>
                    </detail-row>                    
                    <detail-row class="discount-text bold">
                        <detail-item-left>
                            Entergy Discount:
                        </detail-item-left>
                        <detail-item-right>
                            -${item.discount}
                        </detail-item-right>
                    </detail-row>
                    <detail-row class="border-bottom bold">
                        <detail-item-left>
                            Installation Cost Est:
                        </detail-item-left>
                        <detail-item-right>
                            ${item.installation} - ${item.installationMax}
                        </detail-item-right>
                    </detail-row>
                    <detail-row class="bold">
                        <detail-item-left class="total-cost-tooltip">
                            <div>
                            Total Cost:
                            <StyledTooltip arrow title={
                                <React.Fragment>
                                    Price range is an estimate only and may vary depending on home size, obstructions, selected powered items, and generator location. Learn more in the <a href="#faq">FAQ section</a>. 
                                </React.Fragment>
                                }>
                                <IconButton style={{padding: '0px'}}>
                                    <InfoOutlined />
                                </IconButton>
                            </StyledTooltip> 
                            </div>
                        </detail-item-left>
                        <detail-item-right>
                            ${item.total} - ${item.totalMax}
                        </detail-item-right>
                    </detail-row>
                    <detail-row>
                        <detail-item-full class="bold">
                            Estimated Financing Options
                        </detail-item-full>
                    </detail-row>
                    <small-font>
                        <detail-row class="highlight">
                            <detail-item-left>
                                As low as ${item.financing132} / Month
                            </detail-item-left>
                            <detail-item-right>
                                132 Months @ 9.99% APR
                            </detail-item-right>
                        </detail-row>
                        <detail-row>
                            <detail-item-left>
                                As low as ${item.financing61} / Month
                            </detail-item-left>
                            <detail-item-right>
                                61 Months @ 7.99% APR
                            </detail-item-right>
                        </detail-row>
                        <detail-row class="highlight">
                            <detail-item-left>
                            As low as ${item.financing37} / Month
                            </detail-item-left>
                            <detail-item-right>
                                37 Months @ 5.99% APR
                            </detail-item-right>
                        </detail-row>
                        <detail-row>
                            <detail-item-left>
                            As low as ${item.financing18} / Month
                            </detail-item-left>
                            <detail-item-right>
                                18 Months @ 0% APR
                            </detail-item-right>
                        </detail-row>
                    </small-font>
                </detail-column>

                <detail-column>
                    <detail-row class="sub-column">                 
                        <div>
                            <large-font class="bold">Est. Installation in {item.leadTime}</large-font>
                            <StyledTooltip arrow title={
                                <React.Fragment>
                                    Installation time is an estimate and can vary based on many factors. <a href="#faq">View FAQs</a>
                                </React.Fragment>
                            }>
                                <IconButton style={{padding: '0px'}}>
                                    <InfoOutlined />
                                </IconButton>
                            </StyledTooltip> 
                        </div>                          
                    </detail-row>
                    <small-font>
                        <detail-row>
                            <detail-item-full class="bold">
                                Stand out Model Features
                            </detail-item-full>
                        </detail-row>
                            <light-text>
                                { item.modelFeatures.map((item, index) => (
                                    <detail-row key={index} >
                                        <img alt="lightbulb" src={iconLightbulb} height="20" width="16" className="list-icon" />
                                        <detail-item-full>
                                            {item}
                                        </detail-item-full>
                                    </detail-row>
                                ))}
                            </light-text>
                        <detail-row>
                            <detail-item-full class="bold">
                                Standard Guardian Series Features
                            </detail-item-full>
                        </detail-row>
                        <light-text>
                            <detail-row>
                                <img alt="globe" src={iconGlobe} height="20" width="16" className="list-icon" />
                                <detail-item-full>
                                    Free Mobile Link Wi-Fi Connectivity
                                </detail-item-full>
                            </detail-row>
                            <detail-row>
                                <img alt="target" src={iconTarget} height="20" width="16" className="list-icon" />
                                <detail-item-full>
                                    Quiet Test Self-Test Mode
                                </detail-item-full>
                            </detail-row>
                            <detail-row>
                                <img alt="home" src={iconHomeBlue} height="20" width="16" className="list-icon" />
                                <detail-item-full>
                                    Alexa Smart Home Compatible
                                </detail-item-full>
                            </detail-row>
                        </light-text>
                        <detail-row>
                            <detail-item-full class="bold">
                                Purchase and Installation Process
                            </detail-item-full>
                        </detail-row>
                        <light-text>
                            <detail-row>
                                <img alt="phone" src={iconPhoneOrange} height="20" width="16" className="list-icon" />
                                <detail-item-full>
                                    Initial phone call in 1-3 days
                                </detail-item-full>
                            </detail-row>
                            <detail-row>
                                <img alt="home" src={iconHomeOrange} height="20" width="16" className="list-icon" />
                                <detail-item-full>
                                    Home consultation for the final quote in 1-2 weeks
                                </detail-item-full>
                            </detail-row>
                            <detail-row>
                                <img alt="installation" src={iconHardhat} height="20" width="16" className="list-icon" />
                                <detail-item-full>
                                    Installation 12-14 weeks from purchase
                                </detail-item-full>
                            </detail-row>
                        </light-text>
                    </small-font>              
                </detail-column>
            </gen-detail>
        </>
    )
}

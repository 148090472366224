import React from 'react';

export const smbFaqEntries = [
    {
        "summary" : "Is my business eligible for a discount?",
        "id" : "1",
        "detail" : <p>All Entergy New Orleans business customers with peak electrical usage of less than 80kW are eligible for a discount through this program. We’ll review your electrical data after you submit an inquiry and let you know the result via email.
        </p>
    },
    {
        "summary" : "How much will it cost to install a standby generator at my business?",
        "id" : "2",
        "detail" : <p>The price is heavily dependent on the power needs and layout of your business location. Businesses with higher power needs will require larger, more powerful, and expensive generators. Installation costs vary widely based on the layout of your location. A standby generator is a significant investment with prices ranging from ~$15,000 - $50,000+.
        </p>
    },
    {
        "summary" : "Is financing available?",
        "id" : "3",
        "detail" : <p>Yes, the generator expert will be able to provide financing options during the sales process. You may also be able to finance backup power through your bank or business lines of credit.
        </p>
    }
];

import { Launch } from '@mui/icons-material';
import React from 'react';

export const faqEntries = [
    { 
    groupTitle: 'Usage',
    id: 1,
    questions:[
        {
            "summary" : "How to use the transfer switch with surge protection device?",
            "id" : "1",
            "detail" : <>
            <p>The transfer switch with surge protection device comes equipped with a 30-amp power cord. Follow these steps to ensure optimal use of your device.</p>
            <p>Step 1: Position your generator for connection to the transfer switch with surge protection device.</p>
            <p>Step 2: Connect the power cord by inserting the four-blade plug on the connecting cord into the outlet on the generator.</p>
            <p>Step 3: Plug the power cord to the transfer switch with surge protection device by aligning the "thumb guide" on the power cord connector.</p>
            <p>Step 4: Align the "thumb guide" with the front of the transfer switch with surge protection device. Gently insert the connector until you hear a distinct snapping sound, indicating that the connector has securely locked into place.</p>
            </>
        },
        {
            "summary" : "What is the registration process?",
            "id" : "2",
            "detail" : <>
            <p>Step 1 – Sign Up</p>
            <p>Estimated 1-3 Days</p>
            <p>Choose your payment option, fill out the online sign-up form and submit your request. A pilot program administrator will verify your eligibility and provide you with a contractual agreement to take part in the program.</p>
            <p>Step 2 – Installation</p>
            <p>Estimated 1-2 Weeks</p>
            <p>Following contract execution Entergy will schedule an installation date and an Entergy crew will install the Transfer Switch with Surge Protection device at your premise. You do not have to be present for the installation and you should expect power to be turned off during installation.</p>
            <p>Step 3 – Billing</p>
            <p>After Installation</p>
            <p>Billing for the service in accordance with the terms and conditions you have selected will begin after installation. The new charge will appear under “Additional Facilities Charge”.</p>
        </>
        },
        {
            "summary" : "Do I need a generator to use the transfer switch with surge protection device?",
            "id" : "3",
            "detail" : <>
            <p>Yes. While the transfer switch component requires a generator, the surge protection does not.</p>
            <p>The transfer switch with surge protection device serves as an interconnection device, allowing you to directly connect your portable generator to your home's wiring system. In the event of a power outage, your generator becomes the reliable source of emergency backup power. The transfer switch with surge protection device offers a cost-effective alternative to pricey transfer switches and potentially dangerous extension cords.</p>
            </>
            },       
        {
            "summary" : "What size generator do I need?",
            "id" : "4",
            "detail" : <p>For optimal performance during power outages, it is important that you choose a generator capable of running your largest appliances and motors. The transfer switch with surge protection device is specifically designed to be compatible with a 30-amp connector. It is not rated for larger current outputs and will automatically disconnect if generating outputs larger than 30 amps. For further information, please visit <a style={{fontWeight: '600'}} href="https://www.globalpowerproducts.com/transfer-switches/trifecta-manual-transfer-switch/" target="__blank">the transfer switch with surge protection device manufacturer’s website</a><Launch style={{width: '1rem', height: '1rem', color: '#FF1A58', marginBottom: '-.125rem'}}/>.</p>    
        }, 
        {
            "summary" : "Where should the generator be placed?",
            "id" : "5",
            "detail" : <p>It’s important to remember, generators exhaust deadly carbon monoxide gases. It is crucial to never operate the generator indoors, including basements, crawl spaces, and attached garages. Please refer to your generator owner's manual for complete instructions on the safe placement and operation of your generator.</p>    
        }, 
        {
            "summary" : "Is there any potential damage to my appliances?",
            "id" : "6",
            "detail" : <p>The transfer switch with surge protection device is designed to function as an interconnection device and serves to connect your generator to your home. There is no risk of damage to your appliances created by the transfer switch with surge protection device. You should exercise care when selecting your generator to ensure you are buying a high-quality generator. If your appliances are damaged, our customers are eligible to receive up to $1,000 per appliance, with a maximum limit of $10,000 per household.</p>
        }, 
        {
            "summary" : "Is the transfer switch with surge protection device suited for my home?",
            "id" : "7",
            "detail" : <p>Please make sure to complete the required questions to determine if your home is eligible to receive the device. If you meet the qualifications based on the screening questions, a technician will verify it again during the scheduled installation appointment.</p>
        }
    ]
    },
    {
    groupTitle: 'Maintenance',
    id: 2,
    questions:[
        {
            "summary" : "What maintenance is required for the transfer switch with surge protection device?",
            "id" : "8",
            "detail" : <p>The transfer switch with surge protection device does not require regular maintenance. However, we recommend testing your generator every 15-30 days to ensure optimal performance.</p>    
        }
    ]
    },
    {
        groupTitle: 'Operation',
        id: 3,
        questions:[
        {
            "summary" : "Does my utility meter continue to run when using the transfer switch with surge protection device with my generator?",
            "id" : "9",
            "detail" : <p>No. Your utility meter will only run when the utility is supplying electric power to your home. With the transfer switch with surge protection device and your portable generator, you will be automatically disconnected from the utility power supply. The reconnection will occur only when you turn off your generator.</p>
        },
        {
            "summary" : "How can I tell when the utility power is restored?",
            "id" : "10",
            "detail" : <>
            <p>The transfer switch with surge protection device features three indicator lights: a green light, a yellow light, and a red light.</p>
            <ol>
                <li>When the green light is on, it indicates that everything is operating normally, and utility power is available. Once your utility has restored power, you can safely deactivate and disconnect your generator from the transfer switch with surge protection device.</li>
                <li>When the yellow light is on, it means that the household load exceeds 30 amps. It's common for the yellow status light to be on when utility power is available. Before using the transfer switch with surge protection device with a generator, the load needs to be reduced by turning off circuit breakers.</li>
                <li>If the red light is ever on, either on its own or together with the green light, it indicates a potential issue. It is crucial to have the unit serviced right away. Please refer to the (Terms and Conditions) for instructions on how to proceed with the service.</li>
            </ol>
            </>    
        },
        {
            "summary" : "I want surge protection for my home and appliances, can I use the transfer switch with surge protection device?",
            "id" : "11",
            "detail" : <p>Yes. The device comes equipped with surge protection, safeguarding your home and wired appliances from surges exceeding 600 volts.</p>
        },
        {
            "summary" : "Why can't I run my whole house from a portable generator?",
            "id" : "12",
            "detail" : <p>Appliances in a typical household consume relatively low amounts of electricity once they are running. However, the starting process for many appliances demands a significant amount of electricity. To determine the specific start-up wattage needed for each appliance, please refer to the appliance guide.</p>
        },
        {
            "summary" : "What happens when the utility power is restored, and my generator is operating through Entergy transfer switch with surge protection program?",
            "id" : "13",
            "detail" : <p>Your generator will keep powering your home until you decide to turn it off. Once you switch off your generator, the transfer switch with surge protection device will automatically restore your home to utility power. The device comes equipped with a built-in safety feature that prevents the generator's power from flowing back into the utility lines. This not only eliminates hazardous conditions for you but also ensures the safety of utility service personnel.</p>
        },
        {
            "summary" : "Can I use the transfer switch with surge protection device during inclement weather?",
            "id" : "14",
            "detail" : <p>The transfer switch with surge protection device is securely enclosed within the meter socket, posing no hazards or risks. However, it is important to refrain from operating generators in rainy or snowy conditions, unless they are adequately shielded from the elements. For guidance on the safe operation of your generator, please consult the manufacturer, distributor, and/or owner's manual.</p>
        }
    ]
    },
    {
        groupTitle: 'Troubleshoot',
        id: 4,
        questions:[
        {
            "summary" : "What happens if I want the transfer switch with surge protection device removed from my home?",
            "id" : "15",
            "detail" : <p>To remove, replace, or repair your transfer switch with surge protection device, please reach out to Entergy. Do not attempt any removal or repair the device on your own. Only authorized Entergy technicians have the necessary expertise and access to the device.</p>
        },
        {
            "summary" : "I have meter-based surge protection but want a transfer switch with surge protection device. Should I get the hard-wired surge protection?",
            "id" : "16",
            "detail" : <p>The device offers whole house surge protection, eliminating the requirement for meter-based or hard-wired surge protection.</p>
        },
        {
            "summary" : "What happens if the generator gets overloaded?",
            "id" : "17",
            "detail": <>
            <p>For safe use of the transfer switch with surge protection device, it is important that your generator is equipped with a circuit breaker that automatically activates in case of an overload. If your generator lacks this feature, it is not suitable for use. If you trip the circuit breaker on your generator, follow these steps:</p>
            <ol>
                <li>Turn off all the household circuit breakers in your breaker panel.</li>
                <li>Reset the circuit breaker on the generator and restart the generator. For detailed instructions on the proper operation of your generator, please consult the owner's manual.</li>
            </ol>
            </>     
        }    
    ]
    } 
]
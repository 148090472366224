import React from "react"
import './OpcoButton.scss';
import { Box } from "@mui/material";
import {  useNavigate } from "react-router";

const OpcoButton = ({url, image, children}) => {
  const navigate = useNavigate()
  return (
   <Box className="opco-button-grid-item" onClick={() => navigate(url)}>
     
     <img className='opco-button-icon' alt='' src={image}/>
     
     <opco-button-label>
       {children}
     </opco-button-label>

   </Box>
  )
}

export default OpcoButton

import { getSignup, updateStatus } from "../../../api/Admin";
import { CircularProgress, Grid, IconButton } from "@mui/material";
import './SignupInfo.scss'
import StatusChip from "../StatusChip/StatusChip";
import { StyledButtonSentenceCase } from "../../StyledButton";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useState } from "react";
import StatusChange from "../StatusChange/StatusChange";
import toast from "react-hot-toast";
import PageNotFound from "../../PageNotFound/PageNotFound";
import { signupStatuses } from "../../../constants/transferSwitch/signupStatuses";

function SignupInfo({signupId}) {
    const navigate = useNavigate();

    const [currentStatus, setCurrentStatus] = useState('')
    const [openUpdateStatus, setOpenUpdateStatus] = useState(false)
    const queryClient = useQueryClient();

    const { isFetching, data: signup } = useQuery(['signup', signupId], async () => { const { data } = await getSignup(signupId); return data;}, 
      {
        retry: false,
        onSuccess: (data) => {
            setCurrentStatus(data.status)
        },
        onError: (error) => {
            if(error.response?.status === 401)
              navigate("/unauthorized")
            else
              toast.error("There was an issue loading the signup")
          }
      });

    const { mutate: updateStatusMutation, isLoading: isUpdating } = useMutation(
        (newStatus) => updateStatus(signupId, newStatus),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['signup']);
                queryClient.invalidateQueries(['signups']);
                setOpenUpdateStatus(false)
                toast.success("Status successfully updated")
            },
            onError: () => {
                toast.error("There was an issue updating the signup's status")
            },
        }
    );

    const handleUpdateStatus = (newStatus) => {
        updateStatusMutation(newStatus)
    }

    return (
    <Grid container >
        {isFetching ? <CircularProgress className="spinner"/> :
            signup ?
            <>
            <Grid container item xs={12} justifyContent='space-between' className="admin-button-wrapper">
                <IconButton onClick={()=>navigate('/transferSwitch/admin')}>
                    <ArrowBack />
                </IconButton>
                <StyledButtonSentenceCase onClick={()=>setOpenUpdateStatus(true)}>
                    Update status
                </StyledButtonSentenceCase>
            </Grid>
            
            <admin-section>
            <Grid container item xs={12} spacing={2}>
                <Grid container item xs={12} justifyContent='space-between'>
                    <admin-section-header>
                        Sign up information
                    </admin-section-header>
                    <admin-section-header>
                        <StatusChip status={signup.status}/>
                    </admin-section-header>
                    
                </Grid>
                {(signup.status === signupStatuses.DENIED || signup.status === signupStatuses.ERROR || signup.status === signupStatuses.NEEDS_REVIEW) &&
                    <Grid item xs={12}>
                        <admin-question-label>
                            Response from automated validation check
                        </admin-question-label>
                        {signup.statusInfo?.errorMessages?.map(error => 
                            <admin-error-message>
                                {error}
                            </admin-error-message>
                        )}
                    </Grid>
                }
                <Grid item xs={12} md={6}>
                    <admin-question-label>
                        Selected plan
                    </admin-question-label>
                    <admin-question-answer>
                        {signup.selection}
                    </admin-question-answer>
                </Grid>
                <Grid item xs={12} md={6}>
                    <admin-question-label>
                        Selected device
                    </admin-question-label>
                    <admin-question-answer>
                        {signup.device}
                    </admin-question-answer>
                </Grid>
                <Grid item xs={12} md={6}>
                    <admin-question-label>
                        Are you an Entergy employee?
                    </admin-question-label>
                    <admin-question-answer>
                        {signup.entergyEmployee}
                    </admin-question-answer>
                </Grid>
                <Grid item xs={12} md={6}>
                    <admin-question-label>
                        Which Entergy company do you work for?
                    </admin-question-label>
                    <admin-question-answer>
                        {signup.entergyCompany}
                    </admin-question-answer>
                </Grid>
                <Grid item xs={12} md={6}>
                    <admin-question-label>
                        Do you own a portable generator?
                    </admin-question-label>
                    <admin-question-answer>
                        {signup.ownPortableGenerator}
                    </admin-question-answer>
                </Grid>
                <Grid item xs={12} md={6}>
                    <admin-question-label>
                        The 30-amp trifecta transfer switch with surge protection device is the only model available for this pilot. The 30-amp trifecta transfer switch with surge protection device is only compatible with a 7200 W continuous wattage generators or less. Is your generator 7200 continuous wattage or less? 
                    </admin-question-label>
                    <admin-question-answer>
                        {signup.compatibleGenerator}
                    </admin-question-answer>
                </Grid>
                <Grid item xs={12} md={6}>
                    <admin-question-label>
                        Do you know how to safely turn off and on the circuit breakers inside of your home’s electric panel?
                    </admin-question-label>
                    <admin-question-answer>
                        {signup.circuitBreakers}
                    </admin-question-answer>
                </Grid>
                <Grid item xs={12} md={6}>
                    <admin-question-label>
                        Do you have at least 10 feet of clearance next to your home’s outside electric panel? 
                    </admin-question-label>
                    <admin-question-answer>
                        {signup.tenFeetClearance}
                    </admin-question-answer>
                </Grid>
                <Grid item xs={12} md={6}>
                    <admin-question-label>
                        Are you able to safely position your generator in the vicinity of your electric panel and connect the electric cable from the generator to the meter mounted adapter?
                    </admin-question-label>
                    <admin-question-answer>
                        {signup.safetyPositionGenerator}
                    </admin-question-answer>
                </Grid>
                <Grid item xs={12} md={6}>
                    <admin-question-label>
                        Are you the owner of the residence of where the Meter Mounted Adapter is to be installed?
                    </admin-question-label>
                    <admin-question-answer>
                        {signup.ownerOfResidence}
                    </admin-question-answer>
                </Grid>
            </Grid>
            </admin-section>
            <admin-section>
            <Grid container item xs={12}>
                <Grid item xs={12}>
                    <admin-section-header>
                        Customer information
                    </admin-section-header>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <admin-question-label>
                        First name
                    </admin-question-label>
                    <admin-question-answer>
                        {signup.firstName}
                    </admin-question-answer>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <admin-question-label>
                        Last name
                    </admin-question-label>
                    <admin-question-answer>
                        {signup.lastName}
                    </admin-question-answer>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <admin-question-label>
                        Account number
                    </admin-question-label>
                    <admin-question-answer>
                        {signup.accountNumber}
                    </admin-question-answer>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <admin-question-label>
                        Email
                    </admin-question-label>
                    <admin-question-answer>
                        {signup.email}
                    </admin-question-answer>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <admin-question-label>
                        Phone number
                    </admin-question-label>
                    <admin-question-answer>
                        {signup.phoneNumber}
                    </admin-question-answer>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <admin-question-label>
                        Address
                    </admin-question-label>
                    <admin-question-answer>
                        {signup.address}
                    </admin-question-answer>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <admin-question-label>
                        City
                    </admin-question-label>
                    <admin-question-answer>
                        {signup.city}
                    </admin-question-answer>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <admin-question-label>
                        State
                    </admin-question-label>
                    <admin-question-answer>
                        {signup.state}
                    </admin-question-answer>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <admin-question-label>
                        Zip
                    </admin-question-label>
                    <admin-question-answer>
                        {signup.zipCode}
                    </admin-question-answer>
                </Grid>
            </Grid>
            </admin-section>
        </>
        :
        <PageNotFound url='/transferSwitch/admin'>
            Return to Admin Table
        </PageNotFound>
        }
        
        <StatusChange open={openUpdateStatus} onClose={()=>setOpenUpdateStatus(false)} currentStatus={currentStatus} onSave={handleUpdateStatus} loading={isUpdating}/>
    
    </Grid>
    )
}

export default SignupInfo

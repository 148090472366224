import { useNavigate } from 'react-router';
import generatorImg from '../../../assets/generator-generic.png';
import GenCard from '../GenCard/GenCard';
import GenDetail from '../GenDetail/GenDetail';
import { StyledButton } from '../../StyledButton';
import './GenSelection.scss';


export default function GenSelection(props) {
    const navigate = useNavigate();
    const { selectedGenerator, onSelection, suggestedGenerators, loading } = props;
    
    if(suggestedGenerators) {
        return (
            <gen-selection>
                <grid-container class="top-padding">
                    { selectedGenerator ? (
                        <grid-row>
                            <grid-item-full>
                                <GenDetail item={selectedGenerator} />
                            </grid-item-full>
                        </grid-row>
                    ) : <></> }
                    <grid-row>
                        { suggestedGenerators["budget"] ? <grid-item-third>
                            <GenCard type="budget" item={suggestedGenerators["budget"]} onShowDetail={onSelection} selected={(suggestedGenerators["budget"] === selectedGenerator)}/>
                        </grid-item-third> : null }
                        { suggestedGenerators["bestFit"] ? 
                        <grid-item-third>
                            <GenCard type="bestFit" item={suggestedGenerators["bestFit"]} onShowDetail={onSelection} selected={(suggestedGenerators["bestFit"] === selectedGenerator)}/>
                        </grid-item-third> : null }
                        { suggestedGenerators["upgrade"] ?
                        <grid-item-third>
                            <GenCard type="upgrade" item={suggestedGenerators["upgrade"]} onShowDetail={onSelection} selected={(suggestedGenerators["upgrade"] === selectedGenerator)}/>
                        </grid-item-third> : null }                                        
                    </grid-row>
                    <grid-row>
                        <grid-item-full>
                            <center>
                                <StyledButton onClick={() => { navigate("/detail"); }}>NEXT</StyledButton>
                            </center>
                        </grid-item-full>
                    </grid-row>
                </grid-container>
            </gen-selection>
        )
    }
    else {
        return (
            <>
            <grid-container>
                <grid-row>
                    <grid-item-full>
                        <center><h2>
                            {loading ? "Finding a Personalized Generator.." : "Let's Find You a Generator!" }
                        </h2></center>
                    </grid-item-full>
                </grid-row>
                <grid-row>
                    <grid-item-full>
                        <center><img src={generatorImg} alt="Generator" height="250" width="250" /></center>
                    </grid-item-full>
                </grid-row>
                <grid-row>
                    <grid-item-full>
                        <center>Enter your generator needs above to get a customized estimate</center>
                    </grid-item-full>
                </grid-row>
            </grid-container>
            </>
        )
    }
}
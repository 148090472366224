import homeImg from '../../assets/home-night-Bow.jpg';
import homeTwoImg from '../../assets/gen-open.jpg';
import sliderImg from '../../assets/slider3.jpg';

import React from 'react';
import { StyledButton } from '../../components/StyledButton';

export const homeCarousel = [
    {
        "name" : "Your Backup Plan Starts at Home",
        "description" : 
        <>
            <h3>Discounted Generac Generators</h3>
            <ul>
                <li>Get a price estimate in only 3 minutes*</li>
                <li>Connect with a certified Generac dealer to secure a quote</li>
                <li>Financing available</li>
            </ul>
        </>,

        "image" : homeImg,
        "imageAlt" : "Generator"
    },
    {
        "name" : "What's Next?",
        "description" : 
        <>
            <h3>Easy as 1,2,3</h3>
            <ol>
                <li>Fill out and submit your information to have an expert consult with you on your chosen backup power supply</li>
                <li>A verified expert will contact you to schedule a home assessment, review chosen products, total installation costs, payment and financing options</li>
                <li>Installation will be scheduled based on product and install availability</li>
            </ol>
        </>,
        "image" : homeTwoImg,
        "imageAlt" : "Photograph"
    },
    {
        "name" : "Solutions For a Sustainable Tomorrow",
        "description" : 
        <>
            <p>Entergy is committed to building a cleaner, more resilient energy future</p>

            <a href="http://www.entergy.com/future" target="__new"><StyledButton>Learn More</StyledButton></a>
        </>,
        "image" : sliderImg,
        "imageAlt" : "Photograph"
    }
];

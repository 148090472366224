import React from "react";
import FAQ from "../../components/transferSwitch/FAQ/FAQ";
import { HomeCarousel } from "../../components/transferSwitch/HomeCarousel/HomeCarousel";
import { HomeBenefits } from "../../components/transferSwitch/HomeBenefits/HomeBenefits";
import { faqEntries } from "../../constants/transferSwitch/faqEntries";
import { HomeVideo } from "../../components/transferSwitch/HomeVideo/HomeVideo";
import SignUp from "./SignUp";
import Safety from "./Safety";
import Confirm from "./Confirm";
import Banner from "../../components/Banner/Banner";
import { Route, Routes } from "react-router";
import LandingPage from "./LandingPage";
import ProtectedRoute from "../../components/ProtectedRoute";
import Admin from "./Admin";
import AdminSignupInfo from "./AdminSignupInfo";

export default function TransferSwitchHome() {

  return (
    <>
    <Routes>
      <Route path={`/transferSwitch`} element={
        <ProtectedRoute isSharedPage={true}>
          <LandingPage />
        </ProtectedRoute>
      }
      />
      <Route path={`/transferSwitch/:opco`} element={
        <ProtectedRoute>
          <Banner program='transferSwitch' status='closed'/>
          <HomeCarousel />
          <HomeVideo />
          <HomeBenefits />
          <FAQ data={faqEntries} />
        </ProtectedRoute>
      }
      />
      <Route path={`/transferSwitch/:opco/safety`} element={
        <ProtectedRoute>
          <Banner program='transferSwitch' status='closed'/>
          <Safety />
          <FAQ data={faqEntries} />
        </ProtectedRoute>
      }
      />
      <Route path={`/transferSwitch/:opco/signUp`} element={
        <ProtectedRoute>
          <Banner program='transferSwitch' status='closed'/>
          <SignUp />
          <FAQ data={faqEntries} />
      </ProtectedRoute>
      }
      />
      <Route path={`/transferSwitch/:opco/confirm`} element={
        <ProtectedRoute>
          <Confirm />
          <FAQ data={faqEntries} />
        </ProtectedRoute>
      }
      />
      <Route path={`/transferSwitch/admin`} element={
        <ProtectedRoute isSharedPage={true}>
          <Admin />
        </ProtectedRoute>
      }
      />
      <Route path={`/transferSwitch/admin/:signupId`} element={
        <ProtectedRoute isSharedPage={true}>
          <AdminSignupInfo />
        </ProtectedRoute>
      }
      />
    </Routes>
</>
  );
}

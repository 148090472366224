import { useNavigate } from "react-router";
import { StyledButtonSentenceCase } from "../components/StyledButton"
import './Unauthorized.scss'
import Layout from "../components/transferSwitch/Layout/Layout";

export default function Unauthorized() {
    const navigate = useNavigate();

    return (
      <Layout isSharedPage={true}>
      <unauthorized-page>
        <unauthorized-page-text>
          You are not authorized to view this page.
        </unauthorized-page-text>
        <StyledButtonSentenceCase onClick={()=>navigate('/transferSwitch')} size="large">
          Return to home
        </StyledButtonSentenceCase>
      </unauthorized-page>
      </Layout>
    )
}



import React, { useEffect, useRef, useState } from 'react';

import { StyledButtonSentenceCase } from '../../components/StyledButton';

import './SignUp.scss';
import { CircularProgress, Snackbar, Alert, Link, FormControl, FormHelperText, Grid, Checkbox, TextField } from '@mui/material';
import PersonalInfo from '../../components/transferSwitch/PersonalInfo/PersonalInfo';
import SignUpInfo from '../../components/transferSwitch/SignUpInfo/SignUpInfo';
import { createTransferSwitch } from '../../api/TransferSwitchAPI';
import { useNavigate } from 'react-router';
import { allowedAccounts } from '../../constants/transferSwitch/allowedAccountsWithLuling';
import { AccessTime, InfoOutlined } from '@mui/icons-material'
import { StyledTooltip } from '../../components/StyledTooltip';
import { useOpco } from '../../context/opcoContext';
import NavDialog from '../../components/NavDialog/NavDialog';
import toast from 'react-hot-toast';
import { getAfcTerms } from '../../constants/transferSwitch/afcTerms';
import { getProgramTerms } from '../../constants/transferSwitch/programTerms';

export default function SignUp() {

    const [selection, setSelection] = useState();
    const [device, setDevice] = useState();
    const [openTermsDialog1, setOpenTermsDialog1] = useState(false);
    const [openTermsDialog2, setOpenTermsDialog2] = useState(false);
    const [agreeToTerms1, setAgreeToTerms1] = useState(false)
    const [agreeToTerms2, setAgreeToTerms2] = useState(false)

    const {opco, opcoData: { opcoDisplayShort, price7year, price4year, price1year, displayPrice, monthlyPrice }} = useOpco();
        
    const [errors, setErrors ] = useState({});
    const [personalQuestions, setPersonalQuestions] = useState({});
    //const [signUpQuestions, setSignUpQuestions] = useState({"portableGeneratorManufacturer":"", "portableGeneratorModelNumber":"", "portableGeneratorOtherInfo": "", "electricPanelSize": "", "accountOwnerName": "", "accountOwnerRelationship": "", "doorOrWindow": ""});
    const [signUpQuestions, setSignUpQuestions] = useState({"opco": opcoDisplayShort});

    const [submitting, setSubmitting] = useState(false);
    const [data, setData] = useState({});
    const [openError, setOpenError] = useState(false);

    const requiredValues = ["selection", "device", "firstName", "address", "lastName", "city", "phoneNumber", "state", "email", "zipCode", "accountNumber", "entergyEmployee", "compatibleGenerator", "circuitBreakers", "tenFeetClearance", "ownPortableGenerator", "safetyPositionGenerator", "ownerOfResidence"];
    const questionsRef = useRef(null);
    const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:|\\)*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:|\\)+)\])/;
    const phoneRegex = /^\(?\d{3}[.)-]?\s*\d{3}[-.\s]?\d{4}$/;

    const navigate = useNavigate();

    useEffect(() => {
        let newData = {...personalQuestions, ...signUpQuestions, 'selection': selection, 'device': device}
        if(Object.keys(errors).length > 0 ){
            formValidate({...personalQuestions, ...signUpQuestions, 'selection': selection, 'device': device}, getFieldsToValidate(newData))
        }
        setData(newData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [personalQuestions, signUpQuestions, selection, device]);    

    const getCardErrorMessage = () => {
        return "You must select an option."
    }

    const getFieldsToValidate = (data, actionType) => {
        let fieldsToValidate = requiredValues

        if(data['isAccountOwner'] === 'no'){
            fieldsToValidate = [...fieldsToValidate, 'accountOwnerName', 'accountOwnerRelationship']
        }
        if(data['panelLocation'] === 'inside'){
            fieldsToValidate = [...fieldsToValidate, 'doorOrWindow']
        }
        if(data['entergyEmployee'] === 'yes'){
            fieldsToValidate = [...fieldsToValidate, 'entergyCompany'] 
        }
        if(actionType === 'submit'){
            fieldsToValidate = [...fieldsToValidate, 'eSignature'] 
        }

        return fieldsToValidate
    }

    const formValidate = (inData, reqValues, actionType) => {
        
        let errs = {};

        reqValues.forEach((item) => {
            errs[item] = (!inData[item]) ? true : false;
        });

        errs["phoneInvalid"] = phoneRegex.test(inData["phoneNumber"]) ? false: true;
        errs["emailInvalid"] = emailRegex.test(inData["email"]) ? false : true;
        let accountNumTrimed = parseInt(inData.accountNumber);
        errs["accountInvalid"] = allowedAccounts.find((item) => (item === accountNumTrimed.toString())) ? false : true;
        errs["generatorInvalid"] = inData["compatibleGenerator"] === 'no'
        errs["notEntergyEmployee"] = inData["entergyEmployee"] === 'no'
        errs["agreeToTerms1"] = actionType === 'submit' ? !agreeToTerms1 : false
        errs["agreeToTerms2"] = actionType === 'submit' ? !agreeToTerms2 : false
        setErrors({ ...errs });

        return Object.values(errs).every((item) => (item === false));
    }

    const onFormSubmit = (e) => {
        e.preventDefault();

        let fieldsToValidate = getFieldsToValidate(data, 'submit')

        if(formValidate(data, fieldsToValidate, 'submit')) {
            onSubmit(e);
        }
        else 
            toast.error("All required fields must be filled out before submitting.")
        
    }

    const onSubmit = (e) => {
        setSubmitting(true);
        e.preventDefault();

        createTransferSwitch(data).then(() => {
            setSubmitting(false);
            navigate(`/transferSwitch/${opco}/confirm`);
        }).catch(() => {
            setSubmitting(false);
            setOpenError(true);
        });
    }

    const handleTermsClick = (term) => {
        let fieldsToValidate = getFieldsToValidate(data)

        if(formValidate(data, fieldsToValidate, 'terms')) {
            if(term === 'terms1')
                setOpenTermsDialog1(true)
            if(term === 'terms2')
                setOpenTermsDialog2(true)
        }
        else 
            toast.error("All required fields must be filled out before agreeing to the terms and conditions.")   
    }

    return (
        <>
            <grid-container class="extra-padding-2">
                <grid-row-heading class="align-header" >
                    <grid-row-full>
                        <h1 className="bold" >Sign up for Pilot</h1>
                        If your home or business meets the eligibility requirements, Entergy will conduct an installation of one (1) Entergy transfer switch with surge protection device. Additionally, we will provide comprehensive training on how to use the device. The Meter Mounted Adapter and installation services have an approximate value of $1,400 and will be offered for a monthly fee, which includes tax and installation costs.                        
                    </grid-row-full>
                </grid-row-heading>
            </grid-container>

            <grid-container class="extra-padding-2">
                <grid-row-heading style={{textAlign:'center'}}>
                    <grid-row-full>
                        <h2 >Get a transfer switch for as low as 
                            
                            <StyledTooltip arrow title={
                                <React.Fragment>
                                    The cost displayed is an approximate figure. The actual costs for the device, installation, and services amount to ${price7year}, while the operational and maintenance expenses total ${monthlyPrice}.
                                </React.Fragment>
                            }>
                                <Link class='tool-tip-button'>
                                <span class="pink-header">{` $${displayPrice} per month`}</span>
                                <sup>
                                    <InfoOutlined class="info-icon"/>
                                </sup>
                                </Link>
                            </StyledTooltip> 
                        </h2>
                        Included: device + installation cost, maintenance, replacement 
                    </grid-row-full>
                </grid-row-heading>
            </grid-container>

        <form id="frmDetails" onSubmit={onFormSubmit}>    
            <Grid container class='cards-container'>
                <FormControl required error={errors["selection"]}>
                    <Grid container item xs={12}>
                        <Grid item xs={12} sm={6} md={4}>
                            <sign-up-card>
                                <div>
                                    <card-heading>
                                        ${price7year} / month
                                    </card-heading>
                                    <card-subheading>
                                        <AccessTime class='clock-icon'/> 7 Year
                                    </card-subheading>
                                    <card-details>
                                        ${price7year}/mo for 84 months (no post-recovery payments)
                                    </card-details>
                                </div>
                                <button-wrapper>
                                    <StyledButtonSentenceCase 
                                        variant="contained" 
                                        disabled={selection ==='7 year'} 
                                        onClick={() => {setSelection('7 year'); setDevice('30-amp trifecta'); setTimeout(() => {questionsRef.current.scrollIntoView()}, 10);}}
                                    >
                                        {selection ==='7 year' ? "Selected" : "Select this option"}
                                    </StyledButtonSentenceCase>
                                </button-wrapper>
                            </sign-up-card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <sign-up-card>
                                <div>
                                    <card-heading>
                                        ${price4year} / month
                                    </card-heading>
                                    <card-subheading>
                                        <AccessTime class='clock-icon'/> 4 Year
                                    </card-subheading>
                                    <card-details>
                                        ${price4year} for 48 months and ${monthlyPrice}/mo for 36 months after the first 48 months
                                    </card-details>
                                </div>
                                <button-wrapper>
                                    <StyledButtonSentenceCase 
                                        variant="contained" 
                                        disabled={selection ==='4 year'} 
                                        onClick={() => {setSelection('4 year'); setDevice('30-amp trifecta'); setTimeout(() => {questionsRef.current.scrollIntoView()}, 10);}}
                                    >
                                        {selection ==='4 year' ? "Selected" : "Select this option"}
                                    </StyledButtonSentenceCase> 
                                </button-wrapper>
                            </sign-up-card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <sign-up-card>
                                <div>
                                    <card-heading>
                                        ${price1year} / month
                                    </card-heading>
                                    <card-subheading>
                                        <AccessTime class='clock-icon'/> 1 Year
                                    </card-subheading>
                                    <card-details>
                                        ${price1year} for 12 months and ${monthlyPrice}/mo for 72 months after the first 12 months
                                    </card-details>
                                </div>
                                <button-wrapper>        
                                    <StyledButtonSentenceCase 
                                        variant="contained" 
                                        disabled={selection ==='1 year'} 
                                        onClick={() => {setSelection('1 year'); setDevice('30-amp trifecta'); setTimeout(() => {questionsRef.current.scrollIntoView()}, 10);}}
                                    >
                                        {selection ==='1 year' ? "Selected" : "Select this option"}
                                    </StyledButtonSentenceCase>   
                                    <div ref={questionsRef}></div>
                                </button-wrapper>
                            </sign-up-card>
                        </Grid>
                    </Grid>
                    {errors["selection"] && <FormHelperText>{getCardErrorMessage()}</FormHelperText>}
                </FormControl>                       
            </Grid>
            
            <grid-container class="form-container" >
                <SignUpInfo data={signUpQuestions} onDataChange={setSignUpQuestions} errors={errors}/>
                <PersonalInfo data={personalQuestions} onDataChange={setPersonalQuestions} errors={errors}/>
                <grid-container class="terms-container" >
                <FormControl required error={errors["agreeToTerms1"]}>
                    <grid-row>
                        <no-wrap>
                            <grid-item-sm>
                                <Checkbox
                                    onClick={()=>handleTermsClick('terms1')}
                                    className='terms-checkbox'
                                    id="agreeToTerms1"
                                    name="agreeToTerms1"
                                    checked={agreeToTerms1}
                                />  
                            </grid-item-sm>
                            <grid-item>
                                <p class='terms-link' onClick={()=>handleTermsClick('terms1')}>I agree to the payment terms & conditions</p>
                                {errors["agreeToTerms1"] && <FormHelperText style={{marginLeft: '0px', color: '#d32f2f'}}>You must agree to the payment terms & conditions to submit</FormHelperText>}
                            </grid-item>
                        </no-wrap>
                    </grid-row>
                </FormControl>

                <FormControl required error={errors["agreeToTerms2"]}>
                    <grid-row>
                        <no-wrap>
                            <grid-item-sm>
                                <Checkbox 
                                    onClick={()=>handleTermsClick('terms2')}
                                    className='terms-checkbox'
                                    id="agreeToTerms2"
                                    name="agreeToTerms2"
                                    checked={agreeToTerms2}
                                />
                            </grid-item-sm>
                            <grid-item>
                                <p class='terms-link' onClick={()=>handleTermsClick('terms2')}>I agree to the program terms & conditions</p>
                                {errors["agreeToTerms2"] && <FormHelperText style={{marginLeft: '0px', color: '#d32f2f'}}>You must agree to the program terms & conditions to submit</FormHelperText>}
                            </grid-item>
                    </no-wrap>
                </grid-row>
                </FormControl>


                <grid-row>
                    <no-wrap>
                        <grid-item-half>
                            <p class='eSignature-label'>Your Signature</p>
                            <TextField 
                                id="eSignature"
                                name="eSignature"
                                value={data.eSignature ?? ""}
                                onChange={(e) => setData({...data, 'eSignature': e.target.value})}
                                error={errors["eSignature"]}
                                helperText={errors["eSignature"] ? 'You must give your eSignature to submit' : ''}
                                label="Type Full Name" 
                                className="eSignature-field"
                                disabled={!agreeToTerms1 || !agreeToTerms2}
                            />
                        </grid-item-half>
                    </no-wrap>
                </grid-row>
                </grid-container>
            </grid-container>

            <grid-container >
                <grid-row>
                    <center>
                        { submitting ? 
                            <CircularProgress size={'2.6rem'} /> 
                                :
                            <StyledButtonSentenceCase disabled variant="contained" type="submit" >Submit</StyledButtonSentenceCase>
                        }
                    </center>
                </grid-row>
            </grid-container>
        </form>

        <NavDialog open={openTermsDialog1} onClose={() => (setOpenTermsDialog1(false))} width='md'>
            {getAfcTerms(data)}
                <no-wrap>
                    <grid-item-sm>
                        <Checkbox 
                            className='terms-checkbox'
                            id="agreeToTerms1"
                            name="agreeToTerms1"
                            checked={agreeToTerms1}
                            onChange={(e) => setAgreeToTerms1(e.target.checked)}
                        />
                    </grid-item-sm>
                    <grid-item>I agree to the payment terms & conditions</grid-item>
                </no-wrap>
        </NavDialog>
        
        <NavDialog open={openTermsDialog2} onClose={() => (setOpenTermsDialog2(false))} width='md'>
            {getProgramTerms(data)}
            <no-wrap>
                    <grid-item-sm>
                        <Checkbox 
                            className='terms-checkbox'
                            id="agreeToTerms2"
                            name="agreeToTerms2"
                            checked={agreeToTerms2}
                            onChange={(e) => setAgreeToTerms2(e.target.checked)}
                        />
                    </grid-item-sm>
                    <grid-item>I agree to the program terms & conditions</grid-item>
                </no-wrap>
        </NavDialog>

        <Snackbar open={openError} autoHideDuration={6000} onClose={() => (setOpenError(false))}>
            <Alert onClose={() => (setOpenError(false))} severity="error">
                There was an error saving your information. Please try again later. 
            </Alert>
        </Snackbar>
    </>
    );

}
import { Dialog, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import './NavDialog.scss';
import { styled } from '@mui/styles';
import closeImg from '../../assets/icon-close.svg';

const CloseButton = styled(Button)({
    color: "#000",
    backgroundColor: "#FEFEFE",
    fontFamily: "proxima-nova, sans-serif",
    fontSize: "0.9rem",
    fontWeight: "bold",
    textTransform: 'none',
    position: 'absolute',
    right: '0.5rem',
    top: '0.5rem',
    "&:hover": {
        backgroundColor: "#f0f0f0"
    },
    "&:active": {
        backgroundColor: "#f0f0f0",
    }
  });
  
export default function NavDialog(props) {
    const { children, title, open, onClose, width } = props;
    const dialogWidth = width ? width : 'xs'
    return (
        <Dialog open={open} maxWidth={dialogWidth} onClose={onClose}>
            <DialogTitle>
                <dialog-button>
                    <CloseButton onClick={onClose}>
                        Close&nbsp;<img src={closeImg} alt="close" />
                    </CloseButton>
                </dialog-button>
                <dialog-title>
                    {title}
                </dialog-title>
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ paddingBottom: "2rem" }}>
                    <dialog-text>
                        {children}
                    </dialog-text>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}
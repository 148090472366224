import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './HomeVideo.scss';

export function HomeVideo() {
    return (
        <video-wrapper>
            <video-title>Watch the video below to learn more about how the transfer with with surge protection device works</video-title>
            <video-window>
                <div className="container">
                    <iframe
                        title="Entergy transfer switch with surge protection offering"
                        src={"https://player.vimeo.com/video/951970801?h=aeeb59a6f0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"     
                    />
                </div>
                <video-subtitle>
                    <a href="https://generlink.com/files/134351750.pdf" target="_blank" rel="noreferrer">Download guide on how to safely connect your portable generator</a> 
                    <a href={'/spec/Transfer_switch_informational_guide.pdf'} target="_blank" rel="noreferrer">Download transfer switch informational guide</a> 
                </video-subtitle>  
            </video-window>
        </video-wrapper>
    )
}



import React from 'react';
import generatorImg from '../../assets/gen-blue-house.jpg';
import GenDetail from '../../components/resiliency/GenDetail/GenDetail';
import { nextSteps, smbNextSteps } from '../../constants/resiliency/nextSteps';
import { AccessTimeOutlined } from '@mui/icons-material';
import './Confirm.scss';

export default function Confirm(props) {
    const { selectedGenerator } = props;
    const { residential } = props;
    const customerType = residential ? "home" : "business";
    const customerNextSteps = residential ? nextSteps : smbNextSteps;
    
    return (
        <>
            <grid-container>
                <grid-row-heading>
                    <grid-item-half>
                        <img src={generatorImg} alt="Generator" className="headingImg"/>
                    </grid-item-half>
                    <grid-item-half>
                        <detail-heading>
                            <h1 className="pink-highlight bold">Thank You</h1>
                        </detail-heading>
                        <detail-content>
                            You'll receive a phone call from a verified third-party expert to schedule your {customerType} assessment. The installation expert will verify the details of your {customerType} and answer any questions you have about the installation process.
                        </detail-content>
                        <detail-content class="pink-highlight thank-you-note bold">
                        Note: Price range is an estimate only and may vary depending on {customerType} size, obstruction, selected powered items, and generator location. Learn more in the <a href="#faq">FAQ section</a> below.
                        </detail-content>
                    </grid-item-half>
                </grid-row-heading>
            </grid-container>
            <grid-container>
                <grid-row>
                    <grid-item-full>
                        <center>
                        <h2>What to Expect Next</h2>
                        </center>
                    </grid-item-full>
                </grid-row>
                <grid-row-stretch>
                    {customerNextSteps.map((item, index) => (
                        <grid-item-third key={index}>
                            <card-container>
                                <card-row>
                                    <card-item-center>
                                        <img src={item.icon} alt={item.title} />  
                                    </card-item-center>
                                </card-row>
                                <card-row>
                                    <card-heading>
                                        {item.title}
                                    </card-heading>
                                </card-row>
                                <card-content>
                                <card-row class="subheading">
                                    <AccessTimeOutlined />
                                    <card-subheading>{item.subHeading}</card-subheading>
                                </card-row>
                                <card-row>
                                    {item.content}
                                </card-row>
                                </card-content>
                            </card-container>
                        </grid-item-third>
                    ))}
                </grid-row-stretch>
                <grid-row>
                    <grid-item-full>
                        {selectedGenerator ? <GenDetail type="bestFit" item={selectedGenerator} /> : "" }
                    </grid-item-full>
                </grid-row>        
            </grid-container>
        </>
    );

}